import Vue from 'vue'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import PortalVue from 'portal-vue'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import App from './App.vue'
import store from './store'
import router from './router'

import './lib/functions'
import './lib/filters'
import './lib/globals'
import './lib/components'

const token = localStorage.getItem('token')
if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`
axios.defaults.baseURL = window.appData.apiRoot

Vue.use(Vuelidate)
Vue.use(PortalVue)
Vue.use(ElementUI, { locale })

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})