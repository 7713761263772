<template>
  <panel title="Programs Descritpions">
    <el-table
      :data="data">
      <el-table-column label="Program">
        <template slot-scope="scope">
          {{ scope.row.label }}
        </template>
      </el-table-column>

      <el-table-column label="Description">
        <template slot-scope="scope">
          <span v-if="!editing.includes(scope.row.key)">
            {{ scope.row.text }}
          </span>
          <gn-textarea
            v-else
            v-model="form[scope.row.key]"
            maxlength="150"
            label="Description"
            placeholder="Enter description" />
        </template>
      </el-table-column>

      <el-table-column
        label="Actions"
        width="100"
        align="right">
        <template slot-scope="scope">
          <template v-if="editing.includes(scope.row.key)">
            <el-tooltip effect="dark" content="Cancel" placement="top">
              <el-button
                size="mini"
                type="default"
                icon="el-icon-close"
                circle
                @click="cancelEdit(scope.row.key)"/>
            </el-tooltip>

            <el-tooltip effect="dark" content="Save" placement="top">
              <el-button
                size="mini"
                type="success"
                icon="el-icon-check"
                :loading="loading.includes(scope.row.key)"
                circle
                @click="save(scope.row.key)" />
            </el-tooltip>
          </template>
          <el-tooltip
            v-else
            effect="dark"
            content="Edit"
            placement="top">
            <el-button
              size="mini"
              type="default"
              icon="el-icon-edit"
              circle
              @click="openEdit(scope.row.key)" />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </panel>
</template>

<script>
import ManageProgramsConfiguration from './ManageProgramsConfiguration.vue'

export default {
  components: { ManageProgramsConfiguration },

  data () {
    return {
      editing: [],
      form: {
        non_customer: null,
        sight_account: null,
        investment_a: null,
        investment_b: null
      },

      labels: {
        non_customer: 'Non Customer',
        sight_account: 'Sight Account',
        investment_a: 'Investment Fund A',
        investment_b: 'Investment Fund B',
      },

      loading: []
    }
  },

  computed: {
    programsDescription () {
      return this.$store.getters['programDescription/get'](1)
    },

    data () {
      const keys = ['non_customer', 'sight_account', 'investment_a', 'investment_b']
      return keys.map(key => ({
        key,
        label: this.labels[key],
        text: this.programsDescription[key]
      }))
    }
  },

  methods: {
    cancelEdit (key) {
      const index = this.editing.indexOf(key)
      this.editing.splice(index, 1)
    },

    openEdit (key) {
      this.editing.push(key)
      this.form[key] = this.programsDescription[key]
    },

    async save (key) {
      this.loading.push(key)
      try {
        await this.$store.dispatch('programDescription/update', {
          id: 1,
          payload: {
            ...this.programDescription,
            [key]: this.form[key]
          },
        })
        this.cancelEdit(key)
      } catch (error) {
        this.$message.error('There was an error. Please try again.')
      } finally {
        const index = this.loading.indexOf(key)
        this.loading.splice(index, 1)
      }
    }
  }
}
</script>
