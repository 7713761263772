<template>
  <div class="image-panel">
    <div
      class="panel"
      :class="{ right }">
      <div class="content">
        <slot />

        <footer v-if="footer">
          ©{{ year }} {{ system.company_name }}. All rights reserved.
          <div v-if="version" class="mt-5">v{{ version.version }}</div>
        </footer>
      </div>
      <div class="hero">
        <div>
          <img :src="image" alt="" />
          <h2>{{ title }}</h2>
          <h3>{{ subtitle }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },

    subtitle: {
      type: String,
      required: true
    },

    image: {
      type: String,
      required: true
    },

    right: {
      type: Boolean,
      default: false
    },

    footer: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      year: (new Date()).getFullYear()
    }
  },

  computed: {
    version () {
      return this.$store.state.page.version
    },

    system () {
      return this.$store.getters['system/get'](1)
    }
  }
}
</script>

<style lang="scss" scoped>
.image-panel {
  width: 100%;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  .panel {
    width: 1280px;
    max-width: 100%;
    background-color: #fff;
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    &.right {
      flex-direction: row-reverse;
    }
    .content, .hero {
      box-sizing: border-box;
    }
    .content {
      width: 45%;
      padding: 50px 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .hero {
      width: 55%;
      background-color: #409EFF;
      padding: 50px 50px 90px 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      img {
        width: 60%;
      }
      h2 {
        font-size: 30px;
        font-weight: 900;
        margin-top: 50px;
        color: #fff;
      }
      h3 {
        font-size: 17px;
        color: #fff;
        margin-top: 10px;
      }
    }

    footer {
      margin-top: 30px;
      font-size: 16px;
      color: #698db1;
      text-align: center;
    }
  }
}
@media screen and (max-width: 760px) {
  .image-panel {
    .panel .content {
      width: 100%;
    }
    .panel .hero {
      display: none;
    }
  }
}
</style>
