<template>
  <div>
    <portal to="actions">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="openManage = true">
        Add new Reward
      </el-button>
    </portal>

    <panel title="Rewards">
      <rewards-table :rewards="rewards" @edit="openEdit($event)" />
    </panel>

    <manage-reward v-if="openManage" :edit="edit" @close="close()" />
  </div>
</template>

<script>
import store from '../../store'

import ManageReward from './ManageReward'
import RewardsTable from './RewardsTable'

export default {
  async beforeRouteEnter (to, from, next) {
    const promises = [
      store.dispatch('rewards/load')
    ]

    await Promise.all(promises)
    next()
  },

  components: {
    ManageReward,
    RewardsTable
  },

  data () {
    return {
      edit: null,
      openManage: false
    }
  },

  computed: {
    rewards () {
      return this.$store.getters['rewards/list']
    }
  },

  created () {
    this.setPageInfo()
  },

  methods: {
    close () {
      this.openManage = false
      this.edit = null
    },

    openEdit (item) {
      this.edit = item
      this.openManage = true
    },

    async remove (id) {
      try {
        await this.$confirm('Are you sure to delete Reward?')
        await this.$store.dispatch('rewards/delete', id)
      } catch (e) {
        console.error(e)
      }
    },

    setPageInfo () {
      this.$store.commit('page/setPageInfo', {
        title: 'Reward Configuration',
        breadcrumbs: [
          {
            text: 'System Configuration'
          },
          {
            text: 'Reward Configuration'
          }
        ]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.image {
  width: 80px;
  height: 80px;
}
</style>