<template>
  <div>
    <portal to="actions">
      <el-button
        v-if="customer.disapprovalReasons.length"
        size="medium"
        icon="el-icon-time"
        @click="showDisapprovalReasons = true">
        Verification History
      </el-button>
    </portal>

    <el-row :gutter="20">
      <el-col :offset="6" :span="12">
        <el-alert
          v-if="customer.disapprovalReason"
          title="Disapproval reason:"
          :description="customer.disapprovalReason.text"
          type="error"
          class="mb-20"
          :closable="false" />

        <profile-info :profile="customer" />

        <panel title="Contact Information">
          <el-row :gutter="20">
            <el-col :span="8">
              <gn-select
                v-model="form.sex"
                :validator="$v.form.sex"
                :options="sexOptions"
                label="Sex"
                placeholder="What's your sex"
                filterable
                clearable />
            </el-col>
            <el-col :span="8">
              <gn-date-picker
                v-model="form.dob"
                :validator="$v.form.dob"
                :picker-options="pickerOptions"
                type="date"
                label="Date of Birth"
                placeholder="Select Date of Birth" />
            </el-col>
            <el-col :span="8">
              <gn-input
                v-model="form.ssn"
                :validator="$v.form.ssn"
                label="SSN"
                placeholder="Enter SSN" />
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <gn-input
                v-model="form.phone"
                :validator="$v.form.phone"
                label="Phone Number"
                placeholder="Enter Phone Number" />
            </el-col>
            <el-col :span="12">
              <gn-input
                v-model="form.mobile"
                :validator="$v.form.mobile"
                label="Mobile Number"
                placeholder="Enter Mobile Number" />
            </el-col>
          </el-row>
        </panel>

        <panel title="Address Information">
          <el-row :gutter="20">
            <el-col :span="12">
              <gn-input
                v-model="form.address"
                :validator="$v.form.address"
                label="Address"
                placeholder="Enter Address" />
            </el-col>
            <el-col :span="12">
              <gn-input
                v-model="form.address2"
                :validator="$v.form.address2"
                label="Address 2"
                placeholder="Enter Address 2" />
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <gn-select
                v-model="form.country"
                :validator="$v.form.country"
                :options="countryOptions"
                label="Country"
                filterable />
            </el-col>
            <el-col :span="12">
              <gn-select
                v-model="form.state"
                :validator="$v.form.state"
                :disabled="!countryHasState"
                :options="stateOptions"
                label="Province/State"
                filterable />
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <gn-input
                v-model="form.city"
                :validator="$v.form.city"
                label="City"
                placeholder="Enter City" />
            </el-col>
            <el-col :span="12">
              <gn-input
                v-model="form.zip"
                :validator="$v.form.zip"
                label="ZIP / Postal Code"
                placeholder="Enter ZIP / Postal Code" />
            </el-col>
          </el-row>
        </panel>

        <panel title="Identification">
          <template #actions>
            <el-button
              v-if="idTypes.length < idTypeOptions.length"
              type="primary"
              size="small"
              icon="el-icon-plus"
              @click="addId()">
              Add another ID
            </el-button>
            <el-button
              v-else
              type="info"
              size="small"
              icon="el-icon-close"
              @click="removeId()">
              Remove last ID
            </el-button>
          </template>
          <el-row
            v-for="(idType, index) in idTypes"
            :key="index"
            :gutter="20">
            <el-col :span="8">
              <gn-select
                v-model="idType.type"
                :validator="$v.idTypes.$each[index].type"
                :options="getIdTypeOptions(idTypes[index].type)"
                label="Government Issued ID Type "
                placeholder="Select Government Issued ID Type "
                filterable
                clearable />
            </el-col>
            <el-col :span="8">
              <gn-input
                v-model="idType.number"
                :validator="$v.idTypes.$each[index].number"
                label="Government Issued ID Number"
                placeholder="Enter Government Issued ID Number" />
            </el-col>
            <el-col :span="8">
              <gn-upload-input
                v-model="idTypes[index].document"
                :validator="$v.idTypes.$each[index].document"
                :label="idType.type ? getIdOptionText(idType.type) : 'Document'"
                @input="idTypes[index].changed = true" />
              </el-col>
          </el-row>
        </panel>

        <panel>
          <div class="items-center">
            <router-link :to="profileUrl">
              <el-button
                type="default"
                size="small"
                icon="el-icon-close"
                :disabled="loading">
                Cancel
              </el-button>
            </router-link>

            <el-button
              :type="$v.$invalid ? 'primary' : 'success'"
              class="ml-10"
              size="small"
              icon="el-icon-check"
              :loading="loading"
              @click="submit()">
              Submit
            </el-button>
          </div>
        </panel>
      </el-col>
    </el-row>

    <el-dialog
      title="Disapproval Reasons"
      :visible.sync="showDisapprovalReasons"
      width="40%">
      <ul class="gn-list">
        <li
          v-for="reason in customer.disapprovalReasons"
          :key="reason.id">
          <label>Disapproval Reason from {{ reason.createdAt | date }}</label>
          <span>{{ reason.text }}</span>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showDisapprovalReasons = false">Close</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import store from '../../store'
import { required } from 'vuelidate/lib/validators'
import { phone, dashNumbers, alphaNum, alphaVersion } from '../common/validations'

import ProfileInfo from './ProfileInfo'

const idTypeEmpty = {
  type: null,
  number: null,
  document: null,
  changed: null
}

export default {
  async beforeRouteEnter (to, from, next) {
    const user = await store.dispatch('users/get', to.params.id)

    if (user && ['REGISTERED', 'ACTIVE', 'DISABLED'].includes(user.status) && window.appData.currentUser.id === user.id) {
      next()
    } else {
      next({ name: 'CustomerView', params: { id: to.params.id } })

    }

  },

  components: {
    ProfileInfo
  },

  data () {
    return {
      form: {
        sex: null,
        dob: null,
        ssn: null,
        phone: null,
        mobile: null,
        address: null,
        address2: null,
        city: null,
        zip: null,
        country: null,
        state: null
      },
      idTypes: [{ ...idTypeEmpty  }],

      showDisapprovalReasons: false,
      loading: false
    }
  },

  computed: {
    pickerOptions () {
      return {
        disabledDate: this.getDisabledDates
      }
    },

    idTypeOptions () {
      return window.idTypesOptions
    },

    filledIdFields () {
      return this.idTypes.map(idType => idType.type).filter(type => type)
    },

    sexOptions () {
      return window.sexesOptions
    },

    customerId () {
      return this.$route.params.id
    },

    customer () {
      return this.$store.getters['users/get'](this.customerId)
    },

    countryOptions () {
      return window.countries
    },

    stateOptions () {
      if (!this.countryHasState) return null

      return window.states[this.form.country].map(state => ({
        id: state.id,
        text: `${state.id} - ${state.text}`
      }))
    },

    countryHasState () {
      return ['CA', 'US', 'AR'].includes(this.form.country)
    },

    profileUrl () {
      return { name: 'CustomerView', params: { id: this.customerId } }
    }
  },

  watch: {
    'form.country' () {
      this.form.state = null
    }
  },
  
  created () {
    this.setPageTitle()
    this.init()
  },

  methods: {
    getDisabledDates (date) {
      return moment(date).isAfter(moment().subtract(18, 'years'))
    },

    init () {
      if (!this.customer.disapprovalReason) return

      Object.keys(this.form).forEach(key => {
        this.form[key] = this.customer[key]
      })

      if (this.customer.identifications.length) {
        this.idTypes = []
      }

      this.customer.identifications.forEach(id => {
        this.idTypes.push({
          id: id.id,
          type: id.type,
          number: id.number,
          document: new File([], id.document),
          changed: false
        })
      })

      this.$nextTick(() => {
        this.form.state = this.customer.state
      })
    },

    async submit () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.loading = true

      try {
        await this.$store.dispatch('users/update', {
          id: this.customerId,
          payload: {
            ...this.form,
            inVerification: true,
            disapprovalReason: null
          }
        })

        for (let idType of this.idTypes) {
          const payload = idType.id ? {
            id: idType.id,
            payload: {
              type: idType.type,
              number: idType.number
            }
          } : {
            userId: this.customerId,
            type: idType.type,
            number: idType.number
          }
          const identification = await this.$store.dispatch(`identifications/${idType.id ? 'update' : 'create'}`, payload)
          if (idType.changed) {
            await this.$store.dispatch('identifications/upload', { id: identification.id, file: idType.document })
          }
        }
        
        this.$message.success('Thank you for submitting for verification. Your profile is now under review.')
        this.$router.push(this.profileUrl)
      } catch (error) {

      } finally {
        this.loading = false
      }
    },

    getIdOptionText (id) {
      return this.idTypeOptions.find(item => item.id === id)?.text
    },

    addId () {
      this.idTypes.push({ ...idTypeEmpty })
    },

    removeId () {
      this.idTypes.splice(this.idTypes.length - 1, 1)
    },

    getIdTypeOptions (type) {
      return this.idTypeOptions.filter(option => option.id === type || !this.filledIdFields.includes(option.id))
    },

    setPageTitle () {
      const name = `${this.customer.firstName || ''} ${this.customer.lastName || ''}`
      const title = `Verify Profile - ${name}`
      this.$store.commit('page/setPageInfo', {
        title,
        subtitle: this.customer.uuid,
        breadcrumbs: [
          {
            text: 'Customers',
            to: { name: 'Customers' }
          },
          {
            text: name,
            to: this.profileUrl
          },
          {
            text: 'Verify Profile'
          }
        ]
      })
    }
  },

  validations () {
    const rules = {
      form: {
        sex: { required },
        dob: { required },
        phone: { required, phone },
        mobile: { phone },
        ssn: { required, dashNumbers },
        country: { required },
        city: { required },
        address: { required },
        zip: { required, alphaVersion },
      },
      idTypes: {
        $each: {
          type: { required },
          number: { required, alphaNum },
          document: { required },
        }
      }
    }

    if (this.countryHasState) {
      rules.form.state = { required }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
</style>
