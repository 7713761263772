<template>
  <main v-if="user">
    <template v-if="!user || user.status !== 'REGISTERED'">
      <sidebar />
      <div id="page">
        <main-header />
        <page-info />
        <section>
          <router-view />
        </section>
      </div>
    </template>

    <change-password v-else />
  </main>
</template>

<script>
import axios from 'axios'
import store from '../store'

import Sidebar from '../components/layout/Sidebar'
import PageInfo from '../components/layout/PageInfo'
import MainHeader from '../components/layout/Header'
import ChangePassword from '../components/auth/ChangePassword'

export default {
  async beforeRouteEnter (to, from, next) {
    const promises = [
      store.dispatch('readed/load', { params: { 'q[userId]': window.appData.currentUser.id } }),
      store.dispatch('discarded/load', { params: { 'q[userId]': window.appData.currentUser.id } }),
      store.dispatch('systemMessage/load')
    ]

    await Promise.all(promises)
    next()
  },

  components: {
    Sidebar,
    PageInfo,
    MainHeader,
    ChangePassword
  },

  computed: {
    user () {
      return this.$store.state.user.user
    }
  },

  created () {
    this.checkForGeoLoc()
  },

  methods: {
    checkForGeoLoc () {
      const delayGeoLoc = window.sessionStorage.getItem('delayGeoLoc')
      if ('geolocation' in navigator && !this.user.latitude && !this.user.longitude && !delayGeoLoc) {
        this.getCurrentLocation()
      }
    },

    getCurrentLocation () {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const loading = this.$loading()
        const { data } = await axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${position.coords.latitude}+${position.coords.longitude}&key=fb77d23df3be4cfb9a25a28f63887fe1`)
        const payload = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          location: data.results[0]?.formatted
        }
        const user = await this.$store.dispatch('users/update', { id: this.user.id, payload })
        this.$store.commit('user/setUser', user)
        loading.close()
      }, this.delayGeoLoc)
    },

    delayGeoLoc () {
      window.sessionStorage.setItem('delayGeoLoc', true)
    },
  }
}
</script>

<style lang="scss" scoped>
#page {
  width: 100%;
  padding: 100px 20px 20px 90px;
  box-sizing: border-box;
}
</style>