import axios from 'axios';

const state = {
  version: null,
  title: null,
  subtitle: null,
  breadcrumbs: []
}

const actions = {
  async getVersion ({ state, commit }) {
    if (state.version) return state.version
    const response = await axios.get('/version')
    commit('setVersion', response.data.data)
    return response.data.data
  }  
}

const mutations = {
  setVersion (state, data) {
    state.version = data
  },

  setPageInfo (state, data) {
    const title = data.title ? `${data.title} - ${window.appData.title}` : window.appData.title
    document.title = title
    state.title = data.title || null
    state.subtitle = data.subtitle || null
    state.breadcrumbs = data.breadcrumbs || []
  }
}

export default {
  state,
  actions,
  mutations,
  namespaced: true
}