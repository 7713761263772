<template>
  <el-button-group>
    <el-button
      v-for="option in options"
      :key="option.id"
      :size="size"
      :type="value === option.id ? 'primary': 'default'"
      :disabled="value === option.id"
      @click="set(option.id)">
      {{ option.text }}
    </el-button>
  </el-button-group>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Object],
      required: true
    },

    options: {
      type: Array,
      required: true
    },

    size: {
      type: String,
      default: 'small'
    }
  },

  methods: {
    set (value) {
      this.$emit('input', value)
    }
  }
}
</script>