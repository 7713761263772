<template>
  <gn-drawer
    v-model="drawer"
    title="Edit Programs Configuration"
    :before-close="beforeClose"
    @closed="$emit('close')">
    <el-form label-position="top">
      <div
        v-if="error"
        class="textual-error">
        {{ error }}
      </div>

      <gn-input
        v-model="form.sight_acc_bonus"
        :validator="$v.form.sight_acc_bonus"
        label="Sight account token bonus"
        placeholder="Enter Sight account token bonus">
        <template slot="append">%</template>
      </gn-input>

      <gn-input
        v-model="form.a_customer_bonus"
        :validator="$v.form.a_customer_bonus"
        label="Investment A customer token bonus"
        placeholder="Enter Investment A customer token bonus">
        <template slot="append">%</template>
      </gn-input>

      <gn-input
        v-model="form.a_min_deposit"
        :validator="$v.form.a_min_deposit"
        label="Investment A minimum deposit"
        placeholder="Enter Investment A minimum deposit">
        <template slot="prepend">$</template>
      </gn-input>

      <gn-input
        v-model="form.a_max_deposit"
        :validator="$v.form.a_max_deposit"
        label="Investment A maximum deposit"
        placeholder="Enter Investment A maximum deposit">
        <template slot="prepend">$</template>
      </gn-input>

      <gn-input
        v-model="form.b_customer"
        :validator="$v.form.b_customer"
        label="Investment B customer"
        placeholder="Enter Investment B customer">
        <template slot="append">/h</template>
      </gn-input>

      <gn-input
        v-model="form.b_rate"
        :validator="$v.form.b_rate"
        label="Investment B rate"
        placeholder="Enter Investment B rate">
        <template slot="append">%</template>
      </gn-input>

      <gn-input
        v-model="form.b_cost_per_km"
        :validator="$v.form.b_cost_per_km"
        label="Investment B cost per KM"
        placeholder="Enter Investment B cost per KM">
        <template slot="prepend">$</template>
        <template slot="append">/km</template>
      </gn-input>

      <gn-input
        v-model="form.b_min_deposit"
        :validator="$v.form.b_min_deposit"
        label="Investment B minimum deposit"
        placeholder="Enter Investment B minimum deposit">
        <template slot="prepend">$</template>
      </gn-input>
    </el-form>

    <template #footer>
      <el-button
        class="button"
        @click="$emit('close')">
        Cancel
      </el-button>
      <el-button
        class="button"
        type="primary"
        :loading="loading"
        @click="save()">
        {{ loading ? 'Saving...' : 'Save' }}
      </el-button>
    </template>
  </gn-drawer>
</template>

<script>
import { required, decimal } from 'vuelidate/lib/validators'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      form: {
        fintech_customer: null,
        sight_acc_customer: null,
        sight_acc_bonus: null,
        a_customer: null,
        a_customer_bonus: null,
        a_min_deposit: null,
        a_max_deposit: null,
        b_customer: null,
        b_rate: null,
        b_cost_per_km: null,
        b_min_deposit: null,
      },
      loading: false,
      drawer: true,
      error: null
    }
  },

  created () {
    Object.keys(this.form).forEach(key => this.form[key] = this.data[key])
  },

  methods: {
    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      this.loading = true
      try {
        await this.$store.dispatch('programsConfiguration/update', {
          id: 1,
          payload: this.form
        })
        this.$message.success(`Programs Configuration successfully updated.`)
        this.$emit('close')
      } catch (error) {
        this.error = error.response.data.error
      } finally {
        this.loading = false
      }
    },

    async beforeClose (done) {
      if (Object.keys(this.form).every(key => this.form[key] === this.data[key])) return done()

      try {
        await this.$confirm('Are you sure you want to close this?')
        done()
      } catch (e) {
        console.error(e)
      }
    },
  },

  validations: {
    form: {
      fintech_customer: { required, decimal },
      sight_acc_customer: { required, decimal },
      sight_acc_bonus: { required, decimal },
      a_customer: { required, decimal },
      a_customer_bonus: { required, decimal },
      a_min_deposit: { required, decimal },
      a_max_deposit: { required, decimal },
      b_customer: { required, decimal },
      b_rate: { required, decimal },
      b_cost_per_km: { required, decimal },
      b_min_deposit: { required, decimal }
    }
  }
}
</script>