<template>
  <header id="header">
    <div class="left">
      <router-link :to="{ name: 'Dashboard' }">
        <img
          :src="logo"
          class="logo">
      </router-link>

      <div class="search">
        <i class="el-icon-search" />
        <input
          v-model="search"
          type="text"
          placeholder="Start typing to search">
      </div>
    </div>

    <div class="right">
      <ul class="quick-action">
        <li>
          <el-tooltip effect="dark" content="Offers" placement="bottom">
            <i class="el-icon-refresh" />
          </el-tooltip>
        </li>
        <li>
          <el-popover
            ref="popover"
            placement="bottom"
            width="500"
            trigger="click">
            <div class="messages">
              <system-message
                v-for="message in latestMessages"
                :key="message.id"
                :message="message" />

                      
              <el-empty
                v-if="!latestMessages.length"
                :image-size="50"
                description="There are no system messages found." />

              <div
                class="see-all">
                <a
                  class="cursor-pointer"
                  @click="showAllMessages()">
                  {{ messages.length > maxMessages ? 'See all' : 'Inbox' }}
                </a>
              </div>
            </div>

            <template #reference>
              <span>
                <el-badge
                  v-if="unread"
                  :value="unread">
                  <i class="el-icon-chat-line-square" />
                </el-badge>
                <i
                  v-else
                  class="el-icon-chat-line-square" />
              </span>
            </template>
          </el-popover>
        </li>
        <li>
          <el-tooltip effect="dark" content="Notifications" placement="bottom">
            <i class="el-icon-bell" />
          </el-tooltip>
        </li>
        <li>
          <el-tooltip effect="dark" content="Support" placement="bottom">
            <i class="el-icon-help" />
          </el-tooltip>
        </li>
      </ul>

      <el-dropdown
        trigger="click"
        @command="onDropdownItemClick($event)">
        <div class="profile">
          <span>{{ user.firstName }} {{ user.lastName }}</span>
          <avatar :profile="user" :size="30" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="profile">Profile</el-dropdown-item>
          <el-dropdown-item command="settings">Profile Settings</el-dropdown-item>
          <el-dropdown-item
            command="logout"
            divided>
            Logout
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <div class="dark-mode-button">
        <i
          :class="darkMode ? 'el-icon-open' : 'el-icon-turn-off'"
          @click="toggleDarkMode()" />
      </div>
    </div>
  </header>
</template>

<script>
import defaultLogo from '../../../images/logo.png'

import Avatar from '../common/ui/Avatar'
import SystemMessage from '../other/SystemMessage'

export default {
  components: {
    Avatar,
    SystemMessage
  },

  data () {
    return {
      type: 'SYSTEM_MESSAGES',
      search: null,
      maxMessages: 3,
      darkMode: false
    }
  },

  computed: {
    unread () {
      return this.messages.filter(message => !message.read).length
    },

    readed () {
      const data = this.$store.getters['readed/list'] || []
      return data.filter(item => item.itemType === this.type)
    },

    discarded () {
      const data = this.$store.getters['discarded/list'] || []
      return data.filter(item => item.itemType === this.type)
    },

    messages () {
      const messages = this.$store.getters['systemMessage/list'] || []
      return messages.sort((a, b) => {
        const aa = parseInt(a.id)
        const bb = parseInt(b.id)
        if (aa > bb) return -1
        if (aa < bb) return 1
        return 0
      }).map(message => {
        return {
          ...message,
          read: this.readed.some(read => parseInt(read.itemId) === parseInt(message.id)),
          discarded: this.discarded.some(discarded => parseInt(discarded.itemId) === parseInt(message.id)),
        }
      }).filter(message => !message.discarded)
    },

    latestMessages () {
      return this.messages.slice(0, this.maxMessages)
    },

    user () {
      return this.$store.state.user.user
    },

    system () {
      return this.$store.getters['system/get'](1)
    },
    
    logo () {
      return this.system.logo ? `/files/system/${this.system.logo}` : defaultLogo
    }
  },

  created () {
    const darkMode = localStorage.getItem('dark-mode')
    if (darkMode === 'true') {
      this.toggleDarkMode()
    } 
  },

  methods: {
    showAllMessages () {
      this.$refs.popover.showPopper = false
      if (this.$route.name !== 'ViewSystemMessages') this.$router.push({ name: 'ViewSystemMessages' })
    },

    read () {
      const promises = this.latestMessages
        .filter(message => !message.read)
        .map(message => this.$store.dispatch('readed/create', {
          itemType: this.type,
          itemId: message.id
        }))
      Promise.all(promises)
    },

    toggleDarkMode () {
      this.darkMode = !this.darkMode
      this.darkMode ? localStorage.setItem('dark-mode', this.darkMode) : localStorage.removeItem('dark-mode') 
      const body = document.getElementsByTagName('body')[0]
      this.darkMode ? body.classList.add('theme-dark') : body.classList.remove('theme-dark')
    },

    async onDropdownItemClick (item) {
      if (item === 'profile') {
        this.$router.push({ name: 'CustomerView', params: { id: this.user.id } })
      }

      if (item === 'settings') {
        this.$router.push({ name: 'ProfileSettings' })
      }

      if (item === 'logout') {
        this.$loading()
        await this.$store.dispatch('user/logout')
        this.$router.push({ name: 'Login' })
        
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .see-all {
    text-align: center;
    margin-top: 10px;
  }
</style>