<template>
  <div class="panel">
    <div
      v-if="title"
      class="header">
      <div class="title" :class="{ center: centerTitle }">
        <h3>{{ title }}</h3>
        <h4 v-if="subtitle">{{ subtitle }}</h4>
      </div>
      
      <div class="actions">
        <slot name="actions" />
      </div>
    </div>
    <div v-if="$slots.default" class="content">
      <slot />
    </div>
    <div
      v-if="$slots.footer"
      class="footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },

    subtitle: {
      type: String,
      default: null
    },

    centerTitle: {
      type: Boolean,
      default: false
    }
  }
}
</script>
