<template>  
  <div class="wrapper">  
    <panel
      title="Flight Calculator"
      subtitle="The benefit programs are currently only valid for residents in Argentina."
      center-title>
      <gn-select
        v-model="program"
        :options="programOptions"
        label="Select Program"
        placeholder="Select Program" />

      <panel v-if="program === 'NON_CUSTOMER'" class="text">
        Get in touch with our channels to travel at incredible prices.
      </panel>

      <template v-if="['SIGHT_ACCOUNT', 'INVESTMENT_B'].includes(program)">
        <gn-input
          v-model="amount"
          :validator="$v.amount"
          label="Amount"
          :labelAdditional="selectedProgram.amount_text"
          placeholder="Enter Amount">
          <template slot="prepend">$</template>
        </gn-input>

        <span
          v-if="program === 'INVESTMENT_B'"
          class="helper-text">
          Minimum Required amount is $250,001
        </span>
      </template>

      <template v-if="program === 'INVESTMENT_A'">
        <gn-select
          v-model="amount"
          :options="investmentAOptions"
          label="Select Amount"
          :labelAdditional="selectedProgram.amount_text"
          placeholder="Select Amount" />
      </template>

      <div
        v-if="program && program !== 'NON_CUSTOMER'"
        class="buttons">
        <el-button
          class="button"
          @click="clear()">
          Cancel
        </el-button>
        <el-button
          class="button"
          type="primary"
          @click="calculate()">
          Calculate
        </el-button>

        <div
          v-if="result.text && result.value"
          class="result">
          <span class="text">{{ result.text }}:</span>
          <span class="value">{{ result.value }}</span>
          <panel v-if="selectedProgram.resultText" class="text">
            {{ selectedProgram.resultText }}
          </panel>
        </div>
      </div>
    </panel>
  </div>
</template>

<script>
import axios from 'axios'
import { required, decimal, minValue } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      data: null,
      program: null,

      amount: null,

      result: {
        text: null,
        value: null
      },

      programOptions: [
        {
          id: 'NON_CUSTOMER',
          text: 'Non Customer'
        },
        {
          id: 'SIGHT_ACCOUNT',
          text: 'Sight Account',
          amount_text: '(Average Annual Balance)',
          resultText: 'This is the maximum annual consumption that you can acquire to use the fleet at discounted prices. Contact us for more details.'
        },
        {
          id: 'INVESTMENT_A',
          text: 'Investment Fund A',
          amount_text: '(Minimum investment of one year)',
          resultText: 'This is the maximum annual consumption that you can acquire to use the fleet at discounted prices. Contact us for more details.'
        },
        {
          id: 'INVESTMENT_B',
          text: 'Investment Fund B',
          amount_text: '(Minimum investment of one year)',
          resultText: 'You can fly totally free for the amount of kilometers indicated. Contact us for more details.'
        },
      ],

      investmentAOptions: [
        {
          id: 100000,
          text: '$100,000'
        },
        {
          id: 125000,
          text: '$125,000'
        },
        {
          id: 150000,
          text: '$150,000'
        },
        {
          id: 175000,
          text: '$175,000'
        },
        {
          id: 200000,
          text: '$200,000'
        },
        {
          id: 225000,
          text: '$225,000'
        },
        {
          id: 250000,
          text: '$250,000'
        },
      ]
    }
  },

  computed: {
    selectedProgram () {
      return this.programOptions.find(option => option.id === this.program)
    }
  },

  watch: {
    program () {
      this.clear(false)
    }
  },

  created () {
    this.load()
  },

  methods: {
    calculate () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      if (this.program === 'SIGHT_ACCOUNT') {
        this.result.text = 'Maximum cap'
        this.result.value = this.$format.money(this.amount * (this.data.sight_acc_bonus / 100))
      }

      if (this.program === 'INVESTMENT_A') {
        this.result.text = 'Maximum cap'
        this.result.value = this.$format.money(this.amount * (this.data.a_customer_bonus / 100))
      }

      if (this.program === 'INVESTMENT_B') {
        this.result.text = 'Flight KMs'
        this.result.value = Math.round(this.amount * (this.data.b_rate / 100) / this.data.b_cost_per_km) + ' km'
      }
    },

    clear (clearProgram = true) {
      if (clearProgram) {
        this.program = null
      }
      this.amount = null
      this.result = {
        text: null,
        value: null
      }
      this.$v.$reset()
    },

    async load () {
      const loading = this.$loading()
      try {
        const { data } = await axios.get('flight-data')
        this.data = data
      } catch (e) {
        console.error(e)
      } finally {
        loading.close()
      }
    }
  },

  validations () {
    const rules = {}

    if (['SIGHT_ACCOUNT', 'INVESTMENT_A', 'INVESTMENT_B'].includes(this.program)) {
      rules.amount = { required, decimal }
    }

    if (this.program === 'INVESTMENT_B') {
      rules.amount.minValue = minValue(250001)
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  max-width: 500px;
  padding: 100px 0;
  margin: 0 auto;
  .text {
    margin-top: 20px;
    color: #666;
  }
  .buttons {
    margin-top: 20px;
  }

  .helper-text {
    color: #999;
  }

  .result {
    margin-top: 20px;
    .text {
      display: block;
      font-size: 20px;
    }
    .value {
      display: block;
      font-size: 40px;
      font-weight: bold;
    }
  }
}
</style>
