import Vue from 'vue'
import moment from 'moment'

Vue.filter('date', (value, format = 'MMM D, YYYY hh:mm A') => {
  const f = format === 'PD' ? 'dddd, MMMM D YYYY hh:mm:ss A' : format
  return value ? moment(value).format(f) : 'N/A'
})

// Vue.filter('role', (value) => window.roles[value])

Vue.filter('activeStatus', (value) => {
  if (!value) return 'N/A'
  return value === 'ACTIVE' ? 'Active' : 'Inactive'
})

Vue.filter('distance', (value) => {
  return ![null, undefined, NaN].includes(value) ? `${value.toFixed(1)} km` : 'N/A'
})

Vue.filter('money', (value) => {
  return '$ ' + Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(value)
})

Vue.filter('balance', (value) => {
  return '$ ' + Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(value)
})

Vue.filter('status', (status) => {
  return window.userStatuses[status]
})

Vue.filter('classification', (value) => {
  return value ? window.classifications[value] : 'N/A'
})

Vue.filter('postStatus', (status) => {
  return window.postStatuses[status]
})

Vue.filter('tradeStatus', (status) => {
  return window.tradeStatuses[status]
})

Vue.filter('postOfferStatus', (status) => {
  return window.postOfferStatuses[status]
})

Vue.filter('offerStatus', (status) => {
  return window.offerStatuses[status]
})

Vue.filter('uppercase', (value) => value.toUpperCase())

Vue.filter('capitalize', (value) => {
  if (typeof value !== 'string') return ''
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
})

Vue.filter('yesNo', (value) => value ? 'Yes' : 'No')

Vue.filter('fileSize', (size) => {
  size = parseInt(size)
  if (!size) return '0 kB'
  const i = Math.floor( Math.log(size) / Math.log(1024) );
  return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
})


Vue.filter('formattedTwoDecimal', (value) => {
  if(value)
    return (value/1).toFixed(2)
})

Vue.filter('country', (value) => window.countriesOptions[value])

Vue.filter('state', (value) => {
  if (!value) return 'N/A'
  const states = window.states.CA.concat(window.states.US)
  const state = states.find(item => item.id === value)
  return state?.text || value
})