import MainView from '../views/MainView'
import ToolsView from '../views/ToolsView'
import ListingsView from '../views/ListingsView'
import SettingsView from '../views/SettingsView'
import ConfigurationView from '../views/ConfigurationView'

import Login from '../components/auth/Login'
import Rewards from '../components/tools/Rewards'
import Register from '../components/auth/Register'
import Page404 from '../components/common/pages/404'
import Posts from '../components/listings/posts/Posts'
import ChangeLog from '../components/settings/ChangeLog'
import Offers from '../components/listings/offers/Offers'
import Customers from '../components/customers/Customers'
import Dashboard from '../components/dashboard/Dashboard'
import Trades from '../components/listings/trades/Trades'
import PostView from '../components/listings/posts/PostView'
import OfferView from '../components/listings/offers/OfferView'
import CustomerView from '../components/customers/CustomerView'
import Verification from '../components/customers/Verification'
import SystemMessages from '../components/tools/SystemMessages'
import TradeView from '../components/listings/trades/TradeView'
import Administrators from '../components/settings/Administrators'
import ProfileSettings from '../components/profile/ProfileSettings'
import ViewSystemMessages from '../components/other/ViewSystemMessages'
import AccountVerification from '../components/auth/AccountVerification'
import OrganizationSettings from '../components/settings/OrganizationSettings'
import FlightCalculator from '../components/flight-calculator/FlightCalculator'
import VerifyCustomerProfile from '../components/customers/VerifyCustomerProfile'
import RewardConfiguration from '../components/configuration/RewardConfiguration'
import SystemConfiguration from '../components/configuration/SystemConfiguration'
import PointsConfigSettings from '../components/configuration/PointsConfigSettings'
import FlightCalculatorConfiguration from '../components/configuration/FlightCalculatorConfiguration'


export default [
  {
    path: '/login',
    name: 'Login',
    meta: {
      noAuth: true
    },
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      noAuth: true
    },
    component: Register
  },
  {
    path: '/verify-account/:id',
    name: 'AccountVerification',
    meta: {
      noAuth: true
    },
    component: AccountVerification
  },
  {
    path: '/',
    component: MainView,
    redirect: { name: 'Dashboard' },
    meta: {
      auth: true,
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'system-messages',
        name: 'ViewSystemMessages',
        component: ViewSystemMessages
      },
      {
        path: 'customers',
        name: 'Customers',
        component: Customers
      },
      {
        path: 'customers/:id',
        name: 'CustomerView',
        component: CustomerView
      },
      {
        path: 'customers/:id/detailed',
        name: 'CustomerViewFull',
        component: CustomerView
      },
      {
        path: 'customers/:id/verification',
        name: 'CustomerProfileVerification',
        component: Verification
      },
      {
        path: 'customers/:id/verify',
        name: 'VerifyCustomerProfile',
        component: VerifyCustomerProfile
      },
      {
        path: 'listings',
        name: 'Listings',
        component: ListingsView,
        redirect: { name: 'Posts' },
        children: [
          {
            path: 'posts',
            name: 'Posts',
            component: Posts
          },
          {
            path: 'posts/:id',
            name: 'PostView',
            component: PostView
          },
          {
            path: 'offers',
            name: 'Offers',
            component: Offers
          },
          {
            path: 'offers/:id',
            name: 'OfferView',
            component: OfferView
          },
          {
            path: 'trades',
            name: 'Trades',
            component: Trades
          },
          {
            path: 'trades/:id',
            name: 'TradeView',
            component: TradeView
          }
        ]
      },
      {
        path: 'tools',
        name: 'Tools',
        component: ToolsView,
        redirect: { name: 'SystemMessages' },
        children: [
          {
            path: 'system-messages',
            name: 'SystemMessages',
            component: SystemMessages
          },
          {
            path: 'rewards',
            name: 'Rewards',
            component: Rewards
          }
        ]
      },
      {
        path: 'configuration',
        name: 'Configuration',
        component: ConfigurationView,
        redirect: { name: 'SystemConfiguration' },
        children: [
          {
            path: 'system-configuration',
            name: 'SystemConfiguration',
            component: SystemConfiguration
          },
          {
            path: 'points-configuration',
            name: 'PointsConfigSettings',
            component: PointsConfigSettings
          },
          {
            path: 'flight-calculator-configuration',
            name: 'FlightCalculatorConfiguration',
            component: FlightCalculatorConfiguration
          },
          {
            path: 'reward-configuration',
            name: 'RewardConfiguration',
            component: RewardConfiguration
          }
        ]
      },
      {
        path: 'settings',
        name: 'Settings',
        component: SettingsView,
        redirect: { name: 'OrganizationSettings' },
        children: [
          {
            path: 'organization',
            name: 'OrganizationSettings',
            component: OrganizationSettings
          },
          {
            path: 'administrators',
            name: 'Administrators',
            component: Administrators
          },
          {
            path: 'change-log',
            name: 'ChangeLog',
            component: ChangeLog
          }
        ]
      },
      {
        path: 'profile/settings',
        name: 'ProfileSettings',
        component: ProfileSettings
      }
    ]
  },
  {
    path: '/flight-calculator',
    name: 'FlightCalculator',
    component: FlightCalculator
  },
  {
    path: '*',
    name: 'Page404',
    component: Page404
    // redirect: { name: 'Dashboard' }
  }
]
