<template>
  <image-panel
    title="Give and Get"
    subtitle="Stuff exchange platform."
    image="assets/images/account-verify.svg">
    <section class="content-wrapper">
      <h1 class="gn-title">{{ title }}</h1>
      <h2 class="gn-subtitle">{{ text }}</h2>

      <router-link :to="{ name: 'Login', query: { ggapp: true } }">
        <el-button
          class="button"
          type="primary"
          round>
          {{ error ? 'Back to Login' : 'Login' }}
        </el-button>
      </router-link>
    </section>
  </image-panel>
</template>

<script>
import axios from 'axios'

import ImagePanel from '../common/ui/ImagePanel'

export default {
  async beforeRouteEnter (to, from, next) {
    try {
      await axios.put(`/verify-account/${to.params.id}`)
      next()
    } catch (error) {
      next(vm => vm.error = error.response.data.error)
    }
  },

  components: {
    ImagePanel
  },

  data () {
    return {
      error: null
    }
  },

  computed: {
    title () {
      return this.error ? 'Oops! There was an error' : 'Account Activation Complete'
    },

    text () {
      return this.error || 'You have successfully activated your account. Click on button below to Login to your account.'
    }
  }
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  h1 {
    margin-top: 40px;
    text-align: center;
  }
  h2 {
    margin-top: 20px;
    text-align: center;
  }
  .button {
    width: 100%;
    font-size: 16px;
    padding: 15px 50px;
    border-radius: 999px;
    margin-top: 40px;
  }
}
</style>
