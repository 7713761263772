<template>
  <div>
    <programs-description />

    <programs-configuration />

    <plane-inventory />
  </div>
</template>

<script>
import store from '../../store'

import PlaneInventory from './PlaneInventory'
import ProgramsDescription from './ProgramsDescription'
import ProgramsConfiguration from './ProgramsConfiguration'

export default {
  async beforeRouteEnter (to, from, next) {
    const promises = [
      store.dispatch('flightInventory/load'),
      store.dispatch('programDescription/get', 1),
      store.dispatch('programsConfiguration/get', 1)
    ]
    await Promise.all(promises)
    next()
  },

  components: {
    PlaneInventory,
    ProgramsDescription,
    ProgramsConfiguration
  },

  created () {
    this.setPageInfo()
  },

  methods: {
    setPageInfo () {
      this.$store.commit('page/setPageInfo', {
        title: 'Flight Calculator Configuration',
        breadcrumbs: [
          {
            text: 'System Configuration'
          },
          {
            text: 'Flight Calculator Configuration'
          }
        ]
      })
    }
  }
}
</script>