<template>
  <el-table :data="rewards">
    <el-table-column
      label="Header">
      <template slot-scope="scope">
        {{ scope.row.header }}
      </template>
    </el-table-column>

    <el-table-column
      label="Descritpion">
      <template slot-scope="scope">
        {{ scope.row.description }}
      </template>
    </el-table-column>

    <el-table-column
      label="Image">
      <template slot-scope="scope">
        <el-image
          :src="`/files/rewards/${scope.row.image}`"
          :preview-src-list="[`/files/rewards/${scope.row.image}`]"
          class="image"
          fit="contain">
          <div slot="error" class="image-slot with-icon">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </template>
    </el-table-column>

    <el-table-column
      label="Points">
      <template slot-scope="scope">
        <el-tag type="info">{{ scope.row.points }}</el-tag>            
      </template>
    </el-table-column>

    <el-table-column
      label="Status">
      <template slot-scope="scope">
        <el-tag
          :type="scope.row.status === 'ACTIVE' ? 'success' : 'danger'"
          effect="dark"
          size="mini">
          {{ scope.row.status | activeStatus }}
        </el-tag>
      </template>
    </el-table-column>

    <el-table-column
      v-if="!hideActions"
      label="Actions"
      width="200"
      align="right">
      <template slot-scope="scope">
        <el-tooltip
          placement="top"
          content="Edit">
          <el-button
            size="mini"
            type="default"
            icon="el-icon-edit"
            circle
            @click="openEdit(scope.row)" />
        </el-tooltip>

        <el-tooltip
          v-if="scope.row.status !== 'ACTIVE'"
          placement="top"
          content="Delete">
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            circle
            @click="remove(scope.row.id)" />
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    rewards: {
      type: Array,
      required: true
    },

    hideActions: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    openEdit (item) {
      this.$emit('edit', item)
      this.edit = item
      this.openManage = true
    },

    async remove (id) {
      try {
        await this.$confirm('Are you sure to delete Reward?')
        await this.$store.dispatch('rewards/delete', id)
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image {
  width: 80px;
  height: 80px;
}
</style>