<template>
  <div class="messages">
    <panel title="System Messages">
      <template #actions>
        <template v-if="!onlyDiscarded">
          <el-tooltip
            v-if="messages.some(message => !message.read)"
            content="Mark all as read"
            effect="dark"
            placement="top">
            <el-button
              type="success"
              icon="el-icon-check"
              size="small"
              plain
              @click="readAll()" />
          </el-tooltip>

          <el-tooltip
            v-if="messages.some(message => !message.discarded)"
            content="Discard all"
            effect="dark"
            placement="top">
            <el-button
              type="danger"
              icon="el-icon-close"
              size="small"
              plain
              @click="discardAll()" />
          </el-tooltip>
        </template>

        <el-button-group class="ml-20">
          <el-button
            :type="onlyDiscarded ? 'default' : 'primary'"
            size="small"
            @click="onlyDiscarded = false">
            Inbox
          </el-button>
          <el-button
            :type="onlyDiscarded ? 'primary' : 'default'"
            size="small"
            @click="onlyDiscarded = true">
            Discarded
          </el-button>
        </el-button-group>
      </template>

      <system-message
        v-for="message in messages"
        :key="message.id"
        :message="message" />

      <el-empty
        v-if="!messages.length"
        description="There are no system messages found." />
    </panel>
  </div>
</template>

<script>
import SystemMessage from './SystemMessage'

export default {
  components: {
    SystemMessage
  },

  data () {
    return {
      type: 'SYSTEM_MESSAGES',
      onlyDiscarded: false
    }
  },

  computed: {
    read () {
      const data = this.$store.getters['readed/list'] || []
      return data.filter(item => item.itemType === this.type)
    },

    discarded () {
      const data = this.$store.getters['discarded/list'] || []
      return data.filter(item => item.itemType === this.type)
    },

    messages () {
      const messages = this.$store.getters['systemMessage/list'] || []

      return messages.sort((a, b) => {
        const aa = parseInt(a.id)
        const bb = parseInt(b.id)
        if (aa > bb) return -1
        if (aa < bb) return 1
        return 0
      }).map(message => {
        return {
          ...message,
          read: this.read.some(read => parseInt(read.itemId) === parseInt(message.id)),
          discarded: this.discarded.some(discarded => parseInt(discarded.itemId) === parseInt(message.id)),
        }
      }).filter(message => message.discarded === this.onlyDiscarded)
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'System Messages',
      breadcrumbs: [{
        text: 'System Messages'
      }]
    })
  },

  methods: {
    readAll () {
      const promises = this.messages
        .filter(message => !message.read)
        .map(message => this.$store.dispatch('readed/create', {
          itemType: this.type,
          itemId: message.id,
          user: window.appData.currentUser.id
        }))
      Promise.all(promises)
    },

    async discardAll () {
      try {
        await this.$confirm('Are you sure you want to discard all system messages?')
        const loading = this.$loading()
        const promises = this.messages
          .filter(message => !message.discarded)
          .map(message => this.$store.dispatch('discarded/create', {
            itemType: this.type,
            itemId: message.id,
            user: window.appData.currentUser.id
          }))
        await Promise.all(promises)
      } catch (e) {
        console.error(e)
      } finally {
        loading.close()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.messages {
  width: 700px;
  max-width: 100%;
  margin: 0 auto;
}
.ml-20 {
  margin-left: 20px;
}
</style>