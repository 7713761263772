<template>
  <div>
    <navigation :menu="menu" />

    <router-view />
  </div>
</template>

<script>
import Navigation from '../components/common/ui/Navigation'

export default {
  components: {
    Navigation
  },

  computed: {
    menu () {
      return [
        {
          name: 'System Messages',
          to: { name: 'SystemMessages' }
        },
        {
          name: 'Rewards',
          to: { name: 'Rewards' }
        },
      ]
    }
  }
}
</script>
