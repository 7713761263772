<template>
  <el-table
    :data="trades">
    <el-table-column
      label="Trade #"
      width="100">
      <template slot-scope="scope">
        <router-link :to="{ name: 'TradeView', params: { id: scope.row.id } }">
          Trade -{{ scope.row.id }}
        </router-link>
      </template>
    </el-table-column>

    <el-table-column
      label="Post #"
      width="100">
      <template slot-scope="scope">
        <router-link :to="{ name: 'PostView', params: { id: scope.row.post.id } }">
          Post - {{ scope.row.post.id }}
        </router-link>
      </template>
    </el-table-column>

    <el-table-column
      label="Post Profile"
      width="150">
      <template slot-scope="scope">
        <router-link
          :to="{ name: 'CustomerView', params: { id: scope.row.post.user.id } }"
          target="_blank">
          {{ scope.row.post.user.uuid }}
        </router-link>
      </template>
    </el-table-column>

    <el-table-column
      label="Offer #"
      width="100">
      <template slot-scope="scope">
        <router-link :to="{ name: 'PostView', params: { id: scope.row.offer.id } }">
          Offer - {{ scope.row.offer.id }}
        </router-link>
      </template>
    </el-table-column>

    <el-table-column
      label="Offer Profile"
      width="150">
      <template slot-scope="scope">
        <router-link
          :to="{ name: 'CustomerView', params: { id: scope.row.offer.user.id } }"
          target="_blank">
          {{ scope.row.offer.user.uuid }}
        </router-link>
      </template>
    </el-table-column>

    <el-table-column
      label="Trade Date">
      <template slot-scope="scope">
        {{ scope.row.createdAt | date }}
      </template>
    </el-table-column>

    <el-table-column
      label="Status"
      width="100">
      <template slot-scope="scope">
        <el-tag
          :type="statusColors[scope.row.status]"
          effect="dark"
          size="mini">
          {{ scope.row.status | tradeStatus }}
        </el-tag>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    trades: {
      type: Array,
      required: true
    }
  },

  computed: {
    statusColors () {
      return window.tradeStatusColors
    }
  }
}
</script>
