<template>
  <panel>
    <div class="profile-info">
      <component :is="profile.id !== user.id ? 'router-link' : 'div'" :to="customerProfileRoute">
        <avatar
          class="mr-10"
          :profile="profile"
          :size="40"
          :allow-upload="profile.id === user.id" />
      </component>

      <div>
        <router-link :to="customerProfileRoute">
          <span class="name">
            {{ profile.firstName || '' }} {{ profile.lastName || '' }}
            <el-tag
              :type="statusColors[profile.status]"
              effect="dark"
              size="mini"
              class="status">
              {{ profile.status | status }}
            </el-tag>
          </span>
        </router-link>
        <span class="email">{{ profile.email }}</span>
      </div>
    </div>
  </panel>
</template>

<script>
import Avatar from '../common/ui/Avatar'

export default {
  components: {
    Avatar
  },

  props: {
    profile: {
      type: Object,
      required: true
    }
  },

  computed: {
    user () {
      return this.$store.state.user.user
    },

    statusColors () {
      return window.userStatusColors
    },

    customerProfileRoute () {
      return { name: 'CustomerView', params: { id: this.profile.id } }
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-info {
  display: flex;
  align-items: center;
  .name {
    font-size: 16px;
    font-weight: bold;
    color: #091520;
    &:hover {
      color: #409EFF;
    }
  }
  .email {
    display: block;
    font-size: 15px;
    color: #5a738a;
    margin-top: 3px;
  }
  .status {
    margin-left: 5px;
  }
}
</style>
