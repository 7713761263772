<template>
  <gn-drawer
    v-model="drawer"
    :title="`${edit ? 'Edit' : 'Add'} Plane Inventory`"
    :before-close="beforeClose"
    @closed="$emit('close')">
    <el-form label-position="top">
      <div
        v-if="error"
        class="textual-error">
        {{ error }}
      </div>

      <gn-input
        v-model="form.name"
        :validator="$v.form.name"
        label="Plane name">
      </gn-input>

      <gn-input
        v-model="form.val"
        :validator="$v.form.val"
        label="Plane value"
        placeholder="Enter Plane value">
        <template slot="prepend">$</template>
      </gn-input>

      <gn-input
        v-model="form.passengers"
        :validator="$v.form.passengers"
        label="Passengers"
        placeholder="Enter Passengers">
        <template slot="prepend">#</template>
      </gn-input>

      <gn-input
        v-model="form.autonomy"
        :validator="$v.form.autonomy"
        label="Flight autonomy"
        placeholder="Enter Flight autonomy">
        <template slot="append">/km</template>
      </gn-input>

      <gn-input
        v-model="form.kmh"
        :validator="$v.form.kmh"
        label="KM per hour"
        placeholder="Enter KM per hour">
        <template slot="prepend">$</template>
        <template slot="append">km/h</template>
      </gn-input>

      <gn-input
        v-model="form.fintech_phc"
        :validator="$v.form.fintech_phc"
        label="Per hour cost - Fintech Client"
        placeholder="Enter Per hour cost - Fintech Client">
        <template slot="prepend">$</template>
      </gn-input>

      <gn-input
        v-model="form.sight_acc_phc"
        :validator="$v.form.sight_acc_phc"
        label="Per hour cost - Sight Account Client"
        placeholder="Enter Per hour cost - Sight Account Client">
        <template slot="prepend">$</template>
      </gn-input>

      <gn-input
        v-model="form.investemnt_a_phc"
        :validator="$v.form.investemnt_a_phc"
        label="Per hour cost - Investment Fund a Client"
        placeholder="Enter Per hour cost - Investment Fund a Client">
        <template slot="prepend">$</template>
      </gn-input>

      <gn-input
        v-model="form.investemnt_b_phc"
        :validator="$v.form.investemnt_b_phc"
        label="Per hour cost - Investment Fund b Client"
        placeholder="Enter Per hour cost - Investment Fund b Client">
        <template slot="prepend">$</template>
      </gn-input>

      <gn-input
        v-model="form.monthly_expense"
        :validator="$v.form.monthly_expense"
        label="Monthly Expense"
        placeholder="Enter Monthly Expense">
        <template slot="prepend">$</template>
      </gn-input>

      <gn-input
        v-model="form.value_of_trust"
        :validator="$v.form.value_of_trust"
        label="Value part of trust"
        placeholder="Enter Value part of trust">
        <template slot="append">%</template>
      </gn-input>

      <gn-input
        v-model="form.package_sale"
        :validator="$v.form.package_sale"
        label="Presale of hour packages"
        placeholder="Enter Presale of hour packages">
        <template slot="prepend">$</template>
        <template slot="append">/h</template>
      </gn-input>

      <file-uploader
        v-model="images"
        label="Add images"
        class="mt-20 mb-5"
        file-path="flight-inventory"
        :limit="5"
        multiple />
      <span>Recommended image size: 520x260px</span>
    </el-form>

    <template #footer>
      <el-button
        class="button"
        @click="$emit('close')">
        Cancel
      </el-button>
      <el-button
        class="button"
        type="primary"
        :loading="loading"
        @click="save()">
        {{ loading ? 'Saving...' : 'Save' }}
      </el-button>
    </template>
  </gn-drawer>
</template>

<script>
import { required, numeric, decimal } from 'vuelidate/lib/validators'

import FileUploader from '../common/ui/forms/FileUploader'

export default {
  props: {
    edit: {
      type: Object,
      default: null
    }
  },

  components: {
    FileUploader
  },

  data () {
    return {
      form: {
        name: null,
        val: null,
        passengers: null,
        autonomy: null,
        kmh: null,
        fintech_phc: null,
        sight_acc_phc: null,
        investemnt_a_phc: null,
        investemnt_b_phc: null,
        monthly_expense: null,
        value_of_trust: null,
        package_sale: null
      },

      images: [],
      maxImages: 5,

      loading: false,
      drawer: true,
      error: null
    }
  },

  created () {
    if (this.edit) {
      Object.keys(this.form).forEach(key => this.form[key] = this.edit[key])

      const extraImages = []
      if (this.edit.image1) {
        extraImages.push(this.edit.image1)
      }

      for(let i=2; i<=5; i++) {
        if (this.edit[`image${i}`]) {
          extraImages.push(this.edit[`image${i}`])
        }
      }
      
      this.images = extraImages
    }
  },

  methods: {
    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      this.loading = true
      try {
        const action = this.edit ? 'update' : 'create'

        const form = {
          ...this.form
        }

        if (this.edit) {
          for (let i = 0; i < 5; i++) {
            const image = this.images[i]
            form[`image${i+1}`] = typeof image === 'object' || !image ? null : image
          }
        }

        const payload = this.edit ? {
          id: this.edit.id,
          payload: form
        } : form

        const inventory = await this.$store.dispatch(`flightInventory/${action}`, payload)


        for (let index in this.images) {
          index = parseInt(index)
          const file = this.images[index]
          if (typeof file === 'object') {
            index === 0 ? await this.$store.dispatch('flightInventory/upload', { id: inventory.id, file }) : await this.$store.dispatch('flightInventory/upload', {
              id: inventory.id,
              file,
              params: { field: `image${index + 1}` }
            })
          } 
        }

        this.$message.success(`Plane Inventory successfully ${this.edit ? 'updated' : 'added'}.`)
        this.$emit('close')
      } catch (error) {
        console.error(error)
        this.error = error?.response?.data?.error
      } finally {
        this.loading = false
      }
    },

    async beforeClose (done) {
      if (Object.keys(this.form).every(key => this.edit ? this.form[key] === this.edit[key] : !this.form[key])) return done()

      try {
        await this.$confirm('Are you sure you want to close this?')
        done()
      } catch (e) {
        console.error(e)
      }
    }
  },

  validations: {
    form: {
      name: { required },
      val: { required, decimal },
      passengers: { required, numeric },
      autonomy: { required, decimal },
      kmh: { required, decimal },
      fintech_phc: { required, decimal },
      sight_acc_phc: { required, decimal },
      investemnt_a_phc: { required, decimal },
      investemnt_b_phc: { required, decimal },
      monthly_expense: { required, decimal },
      value_of_trust: { required, decimal },
      package_sale: { required, decimal }
    }
  }
}
</script>
