<template>
  <panel title="Programs Configuration">
    <el-table
      :data="data">
      <el-table-column label="Sight account token bonus">
        <template slot-scope="scope">
          {{ scope.row.sight_acc_bonus ? `${scope.row.sight_acc_bonus}%` : 'N/A' }}
        </template>
      </el-table-column>

      <el-table-column label="Investment A customer token bonus">
        <template slot-scope="scope">
          {{ scope.row.a_customer_bonus ? `${scope.row.a_customer_bonus}%` : 'N/A' }}
        </template>
      </el-table-column>

      <el-table-column label="Investment A minimum deposit">
        <template slot-scope="scope">
          {{ scope.row.a_min_deposit ? `$${scope.row.a_min_deposit}` : 'N/A' }}
        </template>
      </el-table-column>

      <el-table-column label="Investment A maximum deposit">
        <template slot-scope="scope">
          {{ scope.row.a_max_deposit ? `$${scope.row.a_max_deposit}` : 'N/A' }}
        </template>
      </el-table-column>

      <el-table-column label="Investment B customer">
        <template slot-scope="scope">
          {{ scope.row.b_customer ? `${scope.row.b_customer}/h` : 'N/A' }}
        </template>
      </el-table-column>

      <el-table-column label="Investment B rate">
        <template slot-scope="scope">
          {{ scope.row.b_rate ? `${scope.row.b_rate}%` : 'N/A' }}
        </template>
      </el-table-column>

      <el-table-column label="Investment B cost per KM">
        <template slot-scope="scope">
          {{ scope.row.b_cost_per_km ? `$${scope.row.b_cost_per_km}/km` : 'N/A' }}
        </template>
      </el-table-column>

      <el-table-column label="Investment B minimum deposit">
        <template slot-scope="scope">
          {{ scope.row.b_min_deposit ? `$${scope.row.b_min_deposit}` : 'N/A' }}
        </template>
      </el-table-column>

      <el-table-column
        label="Actions"
        width="70"
        align="right">
        <el-tooltip effect="dark" content="Edit" placement="top">
          <el-button
            size="mini"
            type="default"
            icon="el-icon-edit"
            circle
            @click="isEdit = true" />
        </el-tooltip>
      </el-table-column>
    </el-table>

    <manage-programs-configuration v-if="isEdit" :data="programsConfiguration" @close="isEdit = false" />
  </panel>
</template>

<script>
import ManageProgramsConfiguration from './ManageProgramsConfiguration.vue'

export default {
  components: { ManageProgramsConfiguration },

  data () {
    return {
      isEdit: false
    }
  },

  computed: {
    programsConfiguration () {
      return this.$store.getters['programsConfiguration/get'](1)
    },

    data () {
      return [this.programsConfiguration]
    }
  }
}
</script>
