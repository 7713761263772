<template>
  <div>
    <portal to="actions">
      <router-link
        v-if="(verificationZero || customer.disapprovalReason) && viewingMyProfile"
        :to="{ name: 'VerifyCustomerProfile', params: { id: customerId } }"
        class="mr-5">
        <el-button
          :type="customer.disapprovalReason ? 'danger' : 'success'"
          size="medium"
          :icon="customer.disapprovalReason ? 'el-icon-edit' : 'el-icon-check'">
          {{ customer.disapprovalReason ? 'Edit & Resubmit Verification' : 'Verify Profile' }}
        </el-button>
      </router-link>

      <el-button
        v-if="canSee && customer.disapprovalReasons.length"
        size="medium"
        icon="el-icon-time"
        @click="showDisapprovalReasons = true">
        Verification History
      </el-button>

      <router-link
        v-if="!detailedView"
        :to="{ name: 'CustomerViewFull', params: { id: customerId } }">
        <el-button
          type="default"
          size="medium"
          icon="el-icon-tickets">
          Detailed Profile View
        </el-button>
      </router-link>
      <router-link
        v-else
        :to="{ name: 'CustomerView', params: { id: customerId } }">
        <el-button
          type="default"
          size="medium"
          icon="el-icon-back">
          Profile Overview
        </el-button>
      </router-link>
    </portal>

    <el-row :gutter="20">
      <el-col :span="6">
        <profile-info :profile="customer" />

        <panel title="Profile Info">
          <ul class="gn-list">
            <li>
              <label>Profile ID:</label>
              <span>{{ customer.uuid }}</span>
            </li>
            <li>
              <label>Created At:</label>
              <span>{{ customer.createdAt | date }}</span>
            </li>
            <li>
              <label>Location by:</label>
              <span>{{ hasGeoloc ? 'Phone' : 'IP' }}</span>
            </li>
            <li>
              <label>Location:</label>
              <template v-if="customer.location || (customer.geoData && customer.geoData.city && customer.geoData.country_name)">
                <span v-if="customer.location">{{ customer.location }}</span>
                <span v-else>{{ customer.geoData.city }}, {{ customer.geoData.country_name }}</span>
                <div class="map-wrapper">
                  <iframe
                    width="100%"
                    height="230"
                    style="border:0"
                    loading="lazy"
                    :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyAbdCULaeVllzbd5eC185MKofyUkyqDPR8${mapQuery}&zoom=17`" />
                </div>
              </template>
              <span v-else>N/A</span>
            </li>
          </ul>
        </panel>

        <documents
          v-if="detailedView && documents.length && canSee"
          :documents="documents" />
        
        <panel title="Profile Points">
          <template #actions>
            <el-tooltip effect="dark" content="Add Points" placement="top">
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-plus"
                @click="addProfilePoints = true" />
            </el-tooltip>

            <el-tag
              v-if="points.length"
              size="medium"
              :type="pointsTotal >= 0 ? 'success' : 'danger'"
              effect="dark">
              {{ pointsTotal }}
            </el-tag>
          </template>

          <template v-if="points.length">
            <ul class="gn-list-2">
              <li
                v-for="points in points"
                :key="points.id">
                <div class="item">
                  <label>{{ points.text }}</label>
                  <span>{{ points.createdAt | date }}</span>
                </div>
                <el-tag size="small" :type="points.points >= 0 ? 'success' : 'danger'">{{ points.points >= 0 ? '+' : '' }}{{ points.points }}</el-tag>
              </li>
            </ul>

            <div class="items-center">
              <el-pagination
                layout="prev, pager, next"
                class="mt-20"
                :total="points.length"
                hide-on-single-page
                background
                @current-change="page = $event">
              </el-pagination>
            </div>
          </template>

          <el-empty
            v-else
            :image-size="50"
            description="This customer has not scored any points yet." />
        </panel>
      </el-col>

      <el-col :span="18">
        <el-alert
          v-if="verificationZero"
          title="Profile not verified."
          :description="viewingMyProfile ? 'Your profile is not verifed yet. To verify it click on button Verify Profile in the top right corner.' : 'This customer profile is not verifed yet.'"
          type="warning"
          class="mb-20"
          :closable="false" />

        <el-alert
          v-if="canSee && customer.disapprovalReason"
          title="Disapproval reason:"
          :description="customer.disapprovalReason.text"
          type="error"
          class="mb-20"
          :closable="false" />

        <panel v-if="customer.inVerification && !customer.disapprovalReason">
          <div class="verification-panel">
            <span>
              <b>Profile in verification.</b>
              {{ profileInVerificationText }}
            </span>
            <router-link :to="{ name: 'CustomerProfileVerification', params: { id: customerId } }">
              <el-button
                type="primary"
                size="small"
                icon="el-icon-check">
                {{ viewingMyProfile ? 'View Submitted Info' : 'Review Profile' }}
              </el-button>
            </router-link>
          </div>
        </panel>

        <profile-detailed-info
          v-if="canSee && detailedView"
          title="Profile Details"
          :customer="customer" />

        <template v-if="!detailedView">
          <panel title="Posts">
            <el-skeleton v-if="loading.posts" />
            <posts-table
              v-else-if="posts.length"
              :posts="posts"
              hide-profile-id />
          </panel>

          <panel title="Offers">
            <el-skeleton v-if="loading.offers" />
            <offers-table
              v-else-if="offers.length"
              :offers="offers"
              hide-profile-id />
          </panel>

          <panel title="Trades">
            <el-skeleton v-if="loading.trades" />
            <trades-table
              v-else-if="trades.length"
              :trades="trades" />
          </panel>
        </template>
      </el-col>
    </el-row>

    <manage-profile-points
      v-if="addProfilePoints"
      :user-id="customerId"
      @close="addProfilePoints = false"
      @add="onProfilePointsAdded($event)" />

    <el-dialog
      title="Disapproval Reasons"
      :visible.sync="showDisapprovalReasons"
      width="40%">
      <ul class="gn-list">
        <li
          v-for="reason in customer.disapprovalReasons"
          :key="reason.id">
          <label>Disapproval Reason from {{ reason.createdAt | date }}</label>
          <span>{{ reason.text }}</span>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showDisapprovalReasons = false">Close</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import store from '../../store'

import ProfileInfo from './ProfileInfo'
import Documents from '../common/ui/Documents'
import PostsTable from '../listings/posts/PostsTable'
import ProfileDetailedInfo from './ProfileDetailedInfo'
import TradesTable from '../listings/trades/TradesTable'
import OffersTable from '../listings/offers/OffersTable'
import ManageProfilePoints from '../configuration/ManageProfilePoints'

export default {
  async beforeRouteEnter (to, from, next) {
    const promises = [
      store.dispatch('users/load'),
      store.dispatch('users/get', to.params.id)
    ]

    try {
      await Promise.all(promises)
      next()
    } catch {
      next({ name: 'Customers' })
    }
  },

  components: {
    Documents,
    PostsTable,
    ProfileInfo,
    OffersTable,
    TradesTable,
    ProfileDetailedInfo,
    ManageProfilePoints
  },

  data () {
    return {
      page: 1,

      loading: {
        posts: true,
        offers: true
      },

      addProfilePoints: false,
      showDisapprovalReasons: false
    }
  },

  computed: {
    hasGeoloc () {
      return !!this.customer?.latitude && !!this.customer?.longitude
    },

    mapQuery () {
      return this.hasGeoloc
        ? `&q=${this.customer.latitude},${this.customer.longitude}`
        : `&q=${this.customer.geoData.latitude}, ${this.customer.geoData.longitude}`
    },

    points () {
      const points = this.customer.points || []
      return points.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
    },

    pointsTotal () {
      return this.points.reduce((total, points) => total + points.points, 0)
    },

    profileInVerificationText () {
      return this.viewingMyProfile ? 'Your profile is now in verification. To view your submitted data, click on button "View Submitted Info" on the right side.' : 'This customer has submitted info for verification. To review it, click on button "Review Profile" on the right side.'
    },

    verificationZero () {
      return ['REGISTERED', 'ACTIVE'].includes(this.customer.status) && !this.customer.inVerification
    },

    viewingMyProfile () {
      return this.user.id === this.customerId
    },

    detailedView () {
      return this.$route.name === 'CustomerViewFull'
    },

    canSee () {
      return this.user.admin || this.viewingMyProfile
    },

    user () {
      return this.$store.state.user.user
    },

    documents () {
      return this.customer.identifications?.map(identification => ({
        id: identification.id,
        name: identification.document,
        info: window.idTypes[identification.type],
        url: `/files/users/${identification.userId}/${identification.document}`
      })) || []
    },

    posts () {
      return this.$store.getters['posts/list'].filter(post => post.userId === this.customerId)
    },

    offers () {
      return this.$store.getters['offers/list'].filter(offer => offer.userId === this.customerId)
    },

    trades () {
      return this.$store.getters['trades/list'].filter(trade => trade.offer.userId === this.customerId || trade.post.userId === this.customerId)
    },

    customerId () {
      return parseInt(this.$route.params.id)
    },

    customer () {
      return this.$store.getters['users/get'](this.customerId)
    }
  },

  watch: {
    '$route.name' () {
      this.setPageTitle()
    },

    customerId () {
      this.setPageTitle()
    }
  },
  
  created () {
    this.loadPosts()
    this.loadOffers()
    this.loadTrades()
    this.setPageTitle()
  },

  methods: {
    onProfilePointsAdded (points) {
      const customer = { ...this.customer }
      customer.points.push(points)
      this.$store.commit('users/update', customer)
      this.addProfilePoints = false
    },

    async loadPosts () {
      this.$set(this.posts, 'trades', true)
      try {
        await this.$store.dispatch('posts/load', { params: { 'q[userId]': this.customerId } })
      } catch (e) {
        console.error(e)
      } finally {
        this.$set(this.loading, 'posts', false)
      }
    },

    async loadOffers () {
      this.$set(this.loading, 'offers', true)
      try {
        await this.$store.dispatch('offers/load', { params: { 'q[userId]': this.customerId } })
      } catch (e) {
        console.error(e)
      } finally {
        this.$set(this.loading, 'offers', false)
      }
    },

    async loadTrades () {
      this.$set(this.loading, 'trades', true)
      try {
        await this.$store.dispatch('trades/load')
      } catch (e) {
        console.error(e)
      } finally {
        this.$set(this.loading, 'trades', false)
      }
    },

    setPageTitle () {
      const name = `${this.customer.firstName || ''} ${this.customer.lastName || ''}`
      const title = this.detailedView ? `${name} - Detailed View` : `${name} - Customer Profile`
      const breadcrumbs = [
        {
          text: 'Customers',
          to: { name: 'Customers' }
        },
        {
          text: this.detailedView ? name : title,
          to: this.detailedView ? { name: 'CustomerView', params: { id: this.customerId } } : undefined
        }
      ]

      if (this.detailedView) {
        breadcrumbs.push({
          text: 'Detailed View'
        })
      }

      this.$store.commit('page/setPageInfo', {
        title,
        subtitle: this.customer.uuid,
        breadcrumbs
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.verification-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    b {
      display: block;
      font-size: 16px;
      margin-top: 3px;
    }
  }
}
.gn-list-2 {
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid rgba(000, 000, 000, 0.1);
    &:last-child {
      border-bottom: none;
    }
    .item {
      label {
        display: block;
        font-size: 15px;
        letter-spacing: 0.4px;
        color: #0e243a;
        margin-bottom: 3px;
      }
      span {
        display: block;
        font-size: 11px;
        color: #5a738a;
      }
    }
  }
}
.map-wrapper {
  margin-top: 20px;
}
</style>
