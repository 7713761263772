<template>
  <div
    class="avatar"
    :style="{
      width: size + 'px',
      height: size + 'px'
    }">
    <img :src="$avatar(user.avatarUrl)">
    <div
      v-if="allowUpload"
      class="overlay"
      @click.stop="chooseAvatar = true">
      <i class="el-icon-camera" />
    </div>

    <el-dialog
      v-if="chooseAvatar"
      title="Choose Avatar"
      width="500px"
      :visible="chooseAvatar"
      @close="close()">
      <template v-if="file">
        <img :src="previewImage" class="preview-image" />

        <div class="items-center mt-20">
          <el-button icon="el-icon-close" size="medium" round @click="clear()">Clear</el-button>
        </div>
      </template>

      <template v-else>
        <div class="items-center">
          <el-button
            type="primary"
            @click="openUpload()">
            <i class="el-icon-upload2" /> Custom image
          </el-button>
        </div>

        <div class="avatars">
          <img
            v-for="i in 20"
            :key="i"
            :class="{ selected: selected === i }"
            :src="$avatar(`default/${i}.png`)"
            @click="selected = i" />
        </div>
      </template>

      <span slot="footer" class="dialog-footer">
        <el-button @click="close()">Close</el-button>
        <el-button
          type="primary"
          :disabled="!canSave"
          @click="save()">Update</el-button>
      </span>
    </el-dialog>

    <input
      v-if="allowUpload"
      v-show="false"
      ref="upload"
      type="file"
      @change="chooseImage($event)" />
  </div>
</template>

<script>
import helperMixin from '../helper-mixin'

export default {
  mixins: [helperMixin],

  props: {
    profile: {
      type: Object,
      required: true
    },

    size: {
      type: Number,
      default: 100
    },

    allowUpload: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      chooseAvatar: false,
      selected: null,
      file: null
    }
  },

  computed: {
    previewImage () {
      return this.file ? URL.createObjectURL(this.file) : null
    },

    canSave () {
      return this.file || (this.selected && (this.profile.avatar || this.selected !== this.profile.avatar_id))
    }
  },

  created () {
    this.selected = !this.profile.avatar ? this.profile.avatar_id : null
  },

  methods: {
    async save () {
      const loading = this.$loading()
      try {
        let user
        if (this.file) user = await this.$store.dispatch('users/upload', { id: this.profile.id, file: this.file })
        if (this.selected) user = await this.$store.dispatch('users/update', { id: this.profile.id, payload: {
          avatar: null,
          avatar_id: this.selected
        }})

        if (this.user.id === this.profile.id) {
          this.$store.commit('user/setUser', user)
        }
        this.$message.success('Avatar updated successfully.')
        this.close()
      } catch (e) {
        this.$message.error('Failed to update avatar.')
      } finally {
        loading.close()
      }
    },

    chooseImage ($e) {
      const file = $e?.target?.files?.[0]
      if (!file) return
      if (!this.isImage(file)) {
        this.$message.error('Only image files are allowed. (.jpg, .jpeg, .png, .gif, .webp)')
        this.clear()
        return
      }
      this.file = file
      this.selected = null
    },

    openUpload () {
      this.$refs.upload.click()
    },

    close () {
      this.chooseAvatar = false
      this.clear()
    },

    clear () {
      this.file = null
      this.selected = !this.profile.avatar ? this.profile.avatar_id : null
      this.$refs.upload.value = null
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  border-radius: 999px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    opacity: 0;
    transition: all ease .2s;
    i {
      font-size: 20px;
    }
  }
  &:hover .overlay {
    opacity: 1;
  }
  .preview-image {
    display: block;
    width: 200px;
    height: 200px;
    border-radius: 999px;
    overflow: hidden;
    margin: 0 auto;
  }
  .avatars {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    img {
      width: calc((100% / 5) - 15px);
      border-radius: 999px;
      border: 4px solid transparent;
      cursor: pointer;
      margin-bottom: 5px;
      &.selected {
        border-color: #409EFF;
      }
    }
  }
}
</style>