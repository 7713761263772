<template>
  <div class="preview-images">
    <div
      v-for="(image, index) in images"
      :key="index"
      class="image">
      <img :src="getPreviewUrl(image)" />
      <div
        class="remove"
        @click="remove(index)">
        <i class="el-icon-delete" />
      </div>
    </div>

    <el-button
      v-if="!limit || images.length < limit"
      class="button"
      type="primary"
      size="medium"
      :disabled="disabled"
      @click="chooseFiles()">
      {{ label }}
    </el-button>

    <input
      v-show="false"
      ref="upload"
      type="file"
      :disabled="disabled"
      :multiple="multiple"
      accept="image/*"
      @blur="$emit('blur')"
      @change="uploadFile($event)" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true
    },

    limit: {
      type: Number,
      default: null
    },

    multiple: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: 'Choose file'
    },

    filePath: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      images: []
    }
  },

  watch: {
    value: {
      handler () {
        this.images = this.value
      },

      immediate: true
    },

    images (images) {
      this.$emit('input', images)
    }
  },

  methods: {
    getPreviewUrl (image) {
      return typeof image === 'string' ? this.$image(this.filePath, image) : URL.createObjectURL(image)
    },

    chooseFiles () {
      this.$refs.upload.click()
    },

    remove (index) {
      this.images.splice(index, 1)
    },

    clear () {
      this.$refs.upload.value = null
    },

    uploadFile ($e) {
      const files = Array.from($e?.target?.files)
      
      files.forEach(file => {
        if (this.images.length === this.limit) {
          this.$message.error(`Maximum ${this.limit} images allowed.`)
          return;
        }
        if (!this.onlyImages || this.isImage(file)) {
          this.images.push(file)
        }
      })

      if (this.onlyImages && files.some(file => !this.isImage(file))) {
        this.$message.error('Only image files are allowed. (.jpg, .jpeg, .png, .gif, .webp)')
      }

      this.clear()
    }
  }
}
</script>

<style lang="scss" scoped>
  .preview-images {
    .image {
      width: 100%;
      height: 0;
      padding-bottom: 50%;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      background-color: #ddd;
      margin-bottom: 10px;
      img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .remove {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.4);
        color: #fff;
        &:hover {
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.7);
        }
      }
    }
  }
</style>