<template>
  <gn-drawer
    :value="true"
    :title="`${edit ? 'Edit' : 'Add'} Reward`"
    :before-close="beforeClose"
    @closed="$emit('close')">
    <el-form label-position="top">
      <div
        v-if="error"
        class="textual-error">
        {{ error }}
      </div>

      <gn-input
        v-model="form.header"
        :validator="$v.form.header"
        maxlength="50"
        label="Header">
      </gn-input>

      <gn-textarea
        v-model="form.description"
        :validator="$v.form.description"
        maxlength="250"
        label="Description"
        placeholder="Enter description" />

      <gn-input
        v-model="form.points"
        :validator="$v.form.points"
        label="Points"
        placeholder="Enter Points" />

      <gn-select
        v-model="form.status"
        :options="statusOptions"
        :validator="$v.form.status"
        label="Status"
        placeholder="Select Status" />

      <gn-upload-input
        v-model="image"
        label="Image"
        only-images />
    </el-form>

    <template #footer>
      <el-button
        class="button"
        @click="$emit('close')">
        Cancel
      </el-button>

      <el-button
        class="button"
        type="primary"
        :loading="loading"
        @click="save()">
        {{ loading ? 'Saving...' : 'Save' }}
      </el-button>
    </template>
  </gn-drawer>
</template>

<script>
import { required, numeric, decimal } from 'vuelidate/lib/validators'

export default {
  props: {
    edit: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      form: {
        header: null,
        description: null,
        points: null,
        status: null
      },
      image: null,

      loading: false,
      drawer: true,
      error: null,

      statusOptions: [
        {
          id: 'ACTIVE',
          text: 'Active',
        },
        {
          id: 'INACTIVE',
          text: 'Inactive',
        }
      ]
    }
  },

  computed: {
    user () {
      return this.$store.state.user.user
    }
  },

  created () {
    if (this.edit) {
      Object.keys(this.form).forEach(key => this.form[key] = this.edit[key])
    }
  },

  methods: {
    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      this.loading = true
      try {
        const action = this.edit ? 'update' : 'create'
        const payload = this.edit ? {
          id: this.edit.id,
          payload: this.form
        } : {
          ...this.form,
          createdAt: 'CURRENT_TIMESTAMP',
          createdBy: this.user.id
        }

        const inventory = await this.$store.dispatch(`rewards/${action}`, payload)

        if (this.image) await this.$store.dispatch('rewards/upload', { id: inventory.id, file: this.image })

        this.$message.success(`Reward successfully ${this.edit ? 'updated' : 'added'}.`)
        this.$emit('close')
      } catch (error) {
        this.error = error.response.data.error
      } finally {
        this.loading = false
      }
    },

    async beforeClose (done) {
      if (Object.keys(this.form).every(key => this.edit ? this.form[key] === this.edit[key] : !this.form[key])) return done()

      try {
        await this.$confirm('Are you sure you want to close this?')
        done()
      } catch (e) {
        console.error(e)
      }
    }
  },

  validations: {
    form: {
      header: { required },
      description: { required },
      points: { required, numeric },
      status: { required }
    }
  }
}
</script>
