import axios from 'axios'

const state = {
  user: null
}

const getters = {
  isLoggedIn: (state) => !!state.user
}

const actions = {
  async loadData ({ state, commit }) {
    if (state.user) return state.user

    try {
      const { data } = await axios.get('/data')
      commit('setUser', data.user)
      return data.user
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async login ({ commit }, payload) {
    try {
      const { data } = await axios.post('/login', payload)
      axios.defaults.headers.common.Authorization = `Bearer ${data.token}`
      localStorage.setItem('token', data.token)
      commit('setUser', data.user)
      return data.user
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async logout ({ commit }) {
    try {
      await axios.post('/logout')
      commit('logout')
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async register (context, payload) {
    try {
      const response = await axios.post('/register', payload)
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const mutations = {
  setUser (state, user) {
    window.appData.currentUser = user
    state.user = user
  },

  logout (state) {
    state.user = null
    delete axios.defaults.headers.common.Authorization
    localStorage.removeItem('token')
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}