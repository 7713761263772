<template>
  <div>
    <panel title="Organization Identification Information">
      <el-row :gutter="20">
        <el-col :span="4">
          <gn-input
            v-model="form.name"
            :validator="$v.form.name"
            :show-as-text="!edit"
            label="Rep First Name" />
        </el-col>
        <el-col :span="4">
          <gn-input
            v-model="form.lastName"
            :validator="$v.form.lastName"
            :show-as-text="!edit"
            label="Rep Last Name" />
        </el-col>
        <el-col :span="4">
          <gn-input
            v-model="form.company_name"
            :validator="$v.form.company_name"
            :show-as-text="!edit"
            label="Company Name" />
        </el-col>
        <el-col :span="6">
          <gn-input
            v-model="form.dba_name"
            :validator="$v.form.dba_name"
            :show-as-text="!edit"
            label="DBA Name" />
        </el-col>
        <el-col :span="6">
          <gn-input
            v-model="form.url"
            :validator="$v.form.url"
            :show-as-text="!edit"
            label="URL" />
        </el-col>
      </el-row>
    </panel>

    <panel title="Organization Address Information">
      <el-row :gutter="20">
        <el-col :span="6">
          <gn-input
            v-model="form.address"
            :validator="$v.form.address"
            :show-as-text="!edit"
            label="Address" />
        </el-col>
        <el-col :span="6">
          <gn-input
            v-model="form.city"
            :validator="$v.form.city"
            :show-as-text="!edit"
            label="City" />
        </el-col>
        <el-col :span="4">
          <gn-input
            v-model="form.postal"
            :validator="$v.form.postal"
            :show-as-text="!edit"
            label="Postal Code/Zip" />
        </el-col>
        <el-col :span="4">
          <gn-select
            v-model="form.country"
            :validator="$v.form.country"
            :show-as-text="!edit"
            :options="countryOptions"
            label="Country"
            filterable />
        </el-col>
        <el-col :span="4">
          <gn-select
            v-model="form.state"
            :validator="$v.form.state"
            :show-as-text="!edit"
            :disabled="!countryHasState"
            :options="stateOptions"
            label="Province/State"
            filterable />
        </el-col>
      </el-row>
    </panel>

    <panel title="Organization Contact Information">
      <el-row :gutter="20">
        <el-col :span="4">
          <gn-input
            v-model="form.phone"
            :validator="$v.form.phone"
            :show-as-text="!edit"
            label="Phone" />
        </el-col>
        <el-col :span="6">
          <gn-input
            v-model="form.email"
            :validator="$v.form.email"
            :show-as-text="!edit"
            label="Email" />
        </el-col>
        <el-col :span="4">
          <gn-input
            v-model="form.main_website"
            :validator="$v.form.main_website"
            :show-as-text="!edit"
            label="Website" />
        </el-col>
        <el-col :span="4">
          <gn-input
            v-model="form.support_phone"
            :validator="$v.form.support_phone"
            :show-as-text="!edit"
            label="Support Phone" />
        </el-col>
        <el-col :span="6">
          <gn-input
            v-model="form.support_email"
            :validator="$v.form.support_email"
            :show-as-text="!edit"
            label="Support Email" />
        </el-col>
      </el-row>
    </panel>

    <panel title="Display Settings">
      <el-row :gutter="20">
        <el-col :span="5">
          <gn-input
            v-model="form.login_header"
            :validator="$v.form.login_header"
            :show-as-text="!edit"
            label="Login Header" />
        </el-col>
        <el-col :span="5">
          <gn-input
            v-model="form.login_text"
            :validator="$v.form.login_text"
            :show-as-text="!edit"
            label="Login Text" />
        </el-col>
        <el-col :span="5">
          <gn-input
            v-model="form.register_header"
            :validator="$v.form.register_header"
            :show-as-text="!edit"
            label="Register Header" />
        </el-col>
        <el-col :span="5">
          <gn-input
            v-model="form.register_text"
            :validator="$v.form.register_text"
            :show-as-text="!edit"
            label="Register Text" />
        </el-col>
        <el-col :span="4">
          <gn-upload-input
            v-if="edit"
            v-model="logo"
            label="Logo"
            only-images />
          <label
            v-if="!edit"
            class="simple-label">
            Logo:
          </label>
          <a
            v-if="system.logo"
            :href="`/files/system/${system.logo}`"
            :class="{ 'simple-label-value': !edit }"
            target="_blank">
            {{ system.logo }}
          </a>
        </el-col>
      </el-row>
    </panel>

    <panel title="Mail Settings">
      <el-row :gutter="20">
        <el-col :span="6">
          <gn-input
            v-model="form.mail_from_name"
            :validator="$v.form.mail_from_name"
            :show-as-text="!edit"
            label="Mail - From Name" />
        </el-col>
        <el-col :span="6">
          <gn-input
            v-model="form.mail_from_email"
            :validator="$v.form.mail_from_email"
            :show-as-text="!edit"
            label="Mail - from Email" />
        </el-col>
        <el-col :span="4">
          <gn-upload-input
            v-if="edit"
            v-model="emailLogo"
            label="Mail Logo"
            only-images />
          <label
            v-if="!edit"
            class="simple-label">
            Mail Logo:
          </label>
          <a
            v-if="system.email_logo"
            :href="`/files/system/${system.email_logo}`"
            :class="{ 'simple-label-value': !edit }"
            target="_blank">
            {{ system.email_logo }}
          </a>
        </el-col>
      </el-row>
    </panel>
    <panel>
      <div class="items-center">
        <el-button
          v-if="!edit"
          class="save"
          type="primary"
          icon="el-icon-edit"
          :loading="loading"
          @click="edit = true">
          Update
        </el-button>

        <template v-else>
          <el-button
            class="save"
            type="white"
            icon="el-icon-close"
            @click="cancel()">
            Cancel
          </el-button>
          <el-button
            class="save"
            type="primary"
            icon="el-icon-edit"
            :loading="loading"
            @click="save()">
            Save
          </el-button>
        </template>
      </div>
    </panel>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      form: {
        name: null,
        lastName: null,
        company_name: null,
        dba_name: null,
        url: null,

        address: null,
        city: null,
        state: null,
        postal: null,
        country: null,

        phone: null,
        email: null,
        support_phone: null,
        main_website: null,
        support_email: null,

        mail_from_name: null,
        mail_from_email: null,
        email_logo: null,

        login_header: null,
        login_text: null,
        register_header: null,
        register_text: null,
        logo: null
      },

      logo: null,
      emailLogo: null,

      edit: false,
      loading: false,

      enableDisableOptions: [{
        id: true,
        text: 'Enable'
      },
      {
        id: false,
        text: 'Disable'
      }]
    }
  },

  computed: {
    system () {
      return this.$store.getters['system/get'](1)
    },

    countryOptions () {
      return window.countries
    },

    stateOptions () {
      if (!this.countryHasState) return null

      return window.states[this.form.country].map(state => ({
        id: state.id,
        text: `${state.id} - ${state.text}`
      }))
    },

    countryHasState () {
      return ['CA', 'US', 'AR'].includes(this.form.country)
    }
  },

  watch: {
    'form.country' () {
      this.form.state = null
    }
  },

  created () {
    this.setPageInfo()
    this.initData()
  },

  methods: {
    initData () {
      this.form = JSON.parse(JSON.stringify(this.system))
      this.$nextTick(() => (this.form.state = this.system.state))
    },

    cancel () {
      this.edit = false
      this.initData()
    },

    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      this.loading = true
      try {
        await this.$store.dispatch('system/update', { id: 1, payload: this.form })

        if (this.emailLogo) await this.$store.dispatch('system/upload', { id: 1, file: this.emailLogo, params: { field: 'email_logo' } })
        if (this.logo) await this.$store.dispatch('system/upload', { id: 1, file: this.logo })
        this.$message.success('Organization Settings updated successfully.')
        this.logo = null
        this.emailLogo = null
        this.edit = false
      } catch (e) {
      } finally {
        this.loading = false
      }
    },

    setPageInfo () {
      this.$store.commit('page/setPageInfo', {
        title: 'Organization Settings',
        breadcrumbs: [
          {
            text: 'Settings'
          },
          {
            text: 'Organization Settings'
          }
        ]
      })
    }
  },

  validations () {
    const rules = {
      form: {
        name: { required },
        lastName: { required },
        company_name: { required },
        dba_name: { required },
        url: { required },
        address: { required },
        city: { required },
        postal: { required },
        country: { required },
        phone: { required },
        email: { required },
        support_phone: { required },
        main_website: { required },
        support_email: { required },
        mail_from_name: { required },
        mail_from_email: { required },
        login_header: { required },
        login_text: { required },
        register_header: { required },
        register_text: { required },
        logo: { required }
      }
    }

    if (this.countryHasState) {
      rules.form.state = { required }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
  .save {
    width: 100%;
    max-width: 200px;
  }
  .simple-label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
  }
  .simple-label-value {
    display: block;
    min-height: 20px;
    line-height: 20px;
    padding: 10px 0;
    font-weight: bold;
  }
</style>