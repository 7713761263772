<template>
  <div>
    <div class="flex-between mb-20">
      <active-tabs
        v-model="filter"
        :options="options" />
    </div>

    <panel :title="title">
      <rewards-table :rewards="rewards" @edit="openEdit($event)" hide-actions />
    </panel>
  </div>
</template>

<script>
import store from '../../store'
import ActiveTabs from '../common/ui/ActiveTabs'
import RewardsTable from '../configuration/RewardsTable'

export default {
  async beforeRouteEnter (to, from, next) {
    const promises = [
      store.dispatch('rewards/load')
    ]

    await Promise.all(promises)
    next()
  },

  components: {
    ActiveTabs,
    RewardsTable
  },

  data () {
    return {
      filter: 'REWARDS',

      options: [
        {
          id:'REWARDS',
          text: 'Rewards'
        },
        {
          id: 'REDEEM',
          text: 'Rewards Redeem'
        },
        {
          id: 'HISTORY',
          text: 'Rewards History'
        }
      ]
    }
  },

  computed: {
    rewards () {
      let rewards = store.getters['rewards/list']

      if (this.filter === 'REDEEM') {
        rewards = []
      }

      if (this.filter === 'HISTORY') {
        rewards = []
      }

      return rewards
    },

    title () {
      return this.options.find(option => option.id === this.filter).text
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'Tools',
      breadcrumbs: [
        {
          text: 'Tools'
        }
      ]
    })
  }
}
</script>