import Vue from 'vue'
import Vuex from 'vuex'
import factory from './factory'

import user from './user'
import page from './page'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    page,
    users: factory('User'),
    trades: factory('Trade'),
    identifications: factory('Identification'),
    posts: factory('Post'),
    offers: factory('Offer'),
    readed: factory('Readed'),
    discarded: factory('Discarded'),
    system: factory('System'),
    changeLog: factory('ChangeLog'),
    postOffer: factory('PostOffer'),
    rewards: factory('Reward'),
    profilePoints: factory('ProfilePoints'),
    programsConfiguration: factory('ProgramsConfiguration'),
    programDescription: factory('ProgramDescription'),
    flightInventory: factory('FlightInventory'),
    systemMessage: factory('SystemMessage')
  }
})