<template>
  <panel title="Plane Inventory">
    <template #actions>
      <el-button
        type="primary"
        size="mini"
        icon="el-icon-plus"
        @click="openManage = true">
        Add
      </el-button>
    </template>

    <el-table
      :data="data">
      <el-table-column label="Plane name">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>

      <el-table-column label="Plane value">
        <template slot-scope="scope">
          {{ scope.row.val | money }}
        </template>
      </el-table-column>

      <el-table-column label="Passengers">
        <template slot-scope="scope">
          {{ scope.row.passengers }}
        </template>
      </el-table-column>

      <el-table-column label="Flight autonomy">
        <template slot-scope="scope">
          {{ `${scope.row.autonomy}km` }}
        </template>
      </el-table-column>

      <el-table-column label="KM per hour">
        <template slot-scope="scope">
          {{ `${scope.row.kmh}km/h` }}
        </template>
      </el-table-column>

      <el-table-column label="Per hour cost - Non-Fintech Client">
        <template slot-scope="scope">
          {{ scope.row.fintech_phc | money }}
        </template>
      </el-table-column>

      <el-table-column label="Per hour cost - Sight Account Client">
        <template slot-scope="scope">
          {{ scope.row.sight_acc_phc | money }}
        </template>
      </el-table-column>

      <el-table-column label="Per hour cost - Investment Fund a Client">
        <template slot-scope="scope">
          {{ scope.row.investemnt_a_phc | money }}
        </template>
      </el-table-column>

      <el-table-column label="Per hour cost - Investment Fund b Client">
        <template slot-scope="scope">
          {{ scope.row.investemnt_b_phc | money }}
        </template>
      </el-table-column>

      <el-table-column label="Monthly Expense">
        <template slot-scope="scope">
          {{ scope.row.monthly_expense | money }}
        </template>
      </el-table-column>

      <el-table-column label="Value part of trust">
        <template slot-scope="scope">
          {{ `${scope.row.value_of_trust}%` }}
        </template>
      </el-table-column>

      <el-table-column label="Value part of trust amount">
        <template slot-scope="scope">
          {{ (scope.row.val * (scope.row.value_of_trust / 100 )) | money }}
        </template>
      </el-table-column>

      <el-table-column label="Presale of hour packages">
        <template slot-scope="scope">
          {{ scope.row.package_sale ? `$${scope.row.package_sale}/h` : 'N/A' }}
        </template>
      </el-table-column>

      <el-table-column label="Images">
        <template slot-scope="scope">
          <el-image
            v-for="image in getImages(scope.row)"
            :key="image"
            style="width: 50px; height: 50px"
            :src="image"
            :preview-src-list="getImages(scope.row)">
          </el-image>
        </template>
      </el-table-column>

      <el-table-column
        label="Actions"
        width="90"
        align="right">
        <template slot-scope="scope">
          <el-tooltip effect="dark" content="Edit" placement="top">
            <el-button
              size="mini"
              type="default"
              icon="el-icon-edit"
              circle
              @click="openEdit(scope.row)"/>
          </el-tooltip>

          <el-tooltip effect="dark" content="Delete" placement="top">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="askDelete(scope.row)" />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <manage-plane-inventory v-if="openManage" :edit="edit" @close="close()" />
  </panel>  
</template>

<script>
import ManagePlaneInventory from './ManagePlaneInventory'

export default {
  components: { ManagePlaneInventory },

  data () {
    return {
      openManage: false,
      edit: null
    }
  },

  computed: {
    data () {
      return this.$store.getters['flightInventory/list']
    }
  },

  methods: {
    getImages (item) {
      const images = []
      for(let i = 1; i <= 5; i++) {
        if (item[`image${i}`]) {
          images.push(`/files/flight-inventory/${item[`image${i}`]}`)
        }         
      }
      return images
    },

    openEdit (item) {
      this.edit = item
      this.openManage = true
    },

    async askDelete ({ id }) {
      try {
        await this.$confirm('Are you sure to delete Plane Inventory?')
        await this.$store.dispatch('flightInventory/delete', id)
      } catch (e) {
        console.error(e)
      }
    },

    close () {
      this.openManage = false
      this.edit = null
    }
  }
}
</script>
