<template>
  <auth
    title="Change Password"
    subtitle="In order to continue, You will need set new password."
    right>
    <el-form
      :model="form"
      label-position="top">
      <div
        v-if="error"
        class="textual-error">
        {{ error }}
      </div>

      <gn-input
        v-model="form.password"
        :validator="$v.form.password"
        :options="{ 'show-password': true }"
        label="Password"
        placeholder="Enter password" />

      <gn-input
        v-model="form.repeatPassword"
        :validator="$v.form.repeatPassword"
        :options="{ 'show-password': true }"
        label="Repeat Password"
        placeholder="Repeat password" />

      <el-button
        class="button"
        type="primary"
        :loading="loading"
        round
        @click="save()">
        Change Password
      </el-button>
    </el-form>
  </auth>
</template>

<script>
import { isTruthy, passwordValidation } from '../common/validations'
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'

import Auth from '../../views/Auth'

export default {
  components: {
    Auth
  },

  data () {
    return {
      form: {
        password: null,
        repeatPassword: null
      },
      loading: false,
      error: null
    }
  },

  computed: {
    user () {
      return this.$store.state.user.user
    },

    system () {
      return this.$store.getters['system/get'](1)
    }
  },

  methods: {
    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      this.loading = true
      try {
        await this.$store.dispatch('users/action', {
          id: this.user.id,
          payload: {
            password: this.form.password
          },
          action: 'SetPassword'
        })
        await this.$store.dispatch('users/update', {
          id: this.user.id,
          payload: {
            status: 'ACTIVE',
            activatedAt: 'CURRENT_TIMESTAMP',
            activationCodeExpires: null
          }
        })
        await this.$store.dispatch('user/logout')
        try {
          await this.$alert(`You have successfully changed your password. You can now log in.`, 'Registration complete', {
            confirmButtonText: 'Go to Login page'
          })
          this.$router.push({ name: 'Login' })
        } catch (e) {
          console.error(e)
        } finally {
          this.clear()
        }
      } catch (error) {
        this.error = error?.response?.data?.error
        this.clear()
      } finally {
        this.loading = false
      }
    },

    clear () {
      this.form = {
        password: null,
        repeatPassword: null
      }
      this.$v.$reset()
    }
  },

  validations: {
    form: {
      password: { required, passwordValidation, minLength: minLength(6) },
      repeatPassword: { required, sameAs: sameAs('password') }
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  width: 100%;
  font-size: 16px;
  padding: 15px 23px;
  border-radius: 999px;
  margin-top: 20px;
}
</style>
