<template>
  <div>
    <panel title="Points Configuration">
      <template #actions>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="addProfilePoints = true">
          Manually add points
        </el-button>
      </template>

      <el-row :gutter="20">
        <el-col :span="6">
          <gn-input
            v-model.number="form.activationPoints"
            :show-as-text="!edit"
            label="Profile Activation" />
        </el-col>
        <el-col :span="6">
          <gn-input
            v-model.number="form.verificationPoints"
            :show-as-text="!edit"
            label="Profile Verification" />
        </el-col>
        <el-col :span="6">
          <gn-input
            v-model.number="form.newItemPoints"
            :show-as-text="!edit"
            label="New Item Post" />
        </el-col>
        <el-col :span="6">
          <gn-input
            v-model.number="form.newFavorPoints"
            :show-as-text="!edit"
            label="New Favor Post" />
        </el-col>
        <el-col :span="6">
          <gn-input
            v-model.number="form.newItemOfferPoints"
            :show-as-text="!edit"
            label="New Item Offer" />
        </el-col>
        <el-col :span="6">
          <gn-input
            v-model.number="form.newFavorOfferPoints"
            :show-as-text="!edit"
            label="New Favor Offer" />
        </el-col>
      </el-row>
    </panel>
    <panel>
      <div class="items-center">
        <el-button
          v-if="!edit"
          class="save"
          type="primary"
          size="medium"
          icon="el-icon-edit"
          :loading="loading"
          @click="edit = true">
          Update
        </el-button>

        <template v-else>
          <el-button
            class="save"
            type="white"
            size="medium"
            icon="el-icon-close"
            @click="cancel()">
            Cancel
          </el-button>

          <el-button
            class="save"
            type="primary"
            size="medium"
            icon="el-icon-edit"
            :loading="loading"
            @click="save()">
            Save
          </el-button>
        </template>
      </div>
    </panel>

    <manage-profile-points
      v-if="addProfilePoints"
      @close="addProfilePoints = false"
      @add="addProfilePoints = false" />
  </div>
</template>

<script>
import store from '../../store'

import Panel from '../common/ui/Panel'
import ManageProfilePoints from './ManageProfilePoints'

export default {
  async beforeRouteEnter (to, from, next) {
    const promises = [
      store.dispatch('users/load')
    ]

    await Promise.all(promises)
    next()
  },

  components: {
    Panel,
    ManageProfilePoints
  },

  data () {
    return {
      form: {
        activationPoints: 0,
        verificationPoints: 0,
        newItemPoints: 0,
        newFavorPoints: 0,
        newItemOfferPoints: 0,
        newFavorOfferPoints: 0
      },

      addProfilePoints: false,

      loading: false,
      edit: false
    }
  },

  computed: {
    system () {
      return this.$store.getters['system/get'](1)
    }
  },

  created () {
    this.setPageInfo()
    this.initData()
  },

  methods: {
    initData () {
      Object.keys(this.form).forEach(key => {
        this.form[key] = this.system[key]
      })
    },

    cancel () {
      this.edit = false
      this.initData()
    },

    async save () {
      this.loading = true
      try {
        await this.$store.dispatch('system/update', { id: 1, payload: this.form })

        this.$message.success('Points Configuration updated successfully.')
        this.edit = false
      } catch (e) {
      } finally {
        this.loading = false
      }
    },

    setPageInfo () {
      this.$store.commit('page/setPageInfo', {
        title: 'Points Configuration',
        breadcrumbs: [
          {
            text: 'System Configuration'
          },
          {
            text: 'Points Configuration'
          }
        ]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .save {
    width: 100%;
    max-width: 200px;
  }
</style>