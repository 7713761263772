<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="18">
        <el-alert
          v-if="post.rejectingReason"
          title="Rejecting reason:"
          type="error"
          class="mb-20"
          :closable="false">
          {{ post.rejectingReason }}
        </el-alert>

        <panel
          :title="post.headline"
          class="post">
          <div class="description">
            <h3>Description:</h3>
            <span v-html="post.description" />
          </div>

          <div class="images">
            <div class="featured-image">
              <img
                v-if="post.image"
                :src="`/files/posts/${post.image}`"
                @click="previewImage = 0" />
            </div>
            <div
              v-if="extraImages.length"
              class="extra-images"
              :class="`images-${extraImages.length}`">
              <div
                v-for="(image, index) in extraImages"
                :key="image + index"
                class="extra-image">
                <img
                  :src="image"
                  @click="previewImage = index + 1" />
              </div>
            </div>
          </div>
        </panel>

        <panel
          title="Offers">
          <template #actions>
            <el-button
              v-if="post.status === 'ACTIVE' && post.createdBy.id !== user.id"
              type="primary"
              size="small"
              icon="el-icon-plus"
              @click="manageOffer = true">
              Make Offer
            </el-button>
          </template>

          <custom-offers-table
            v-if="postOffers.length"
            :post="post"
            :offers="postOffers"
            for-posts />
        </panel>
      </el-col>

      <el-col :span="6">
        <panel title="Post Info">
          <template #actions>
            <el-tag
              :type="statusColors[post.status]"
              effect="dark"
              size="small">
              {{ post.status | postStatus }}
            </el-tag>
          </template>

          <ul class="gn-list">
            <li>
              <label>Post ID:</label>
              <span>{{ post.id }}</span>
            </li>
            <li>
              <label>Created At:</label>
              <span>{{ post.createdAt | date }}</span>
            </li>
            <li>
              <label>Expiry Date:</label>
              <span>{{ post.expiringAt | date }}</span>
            </li>
            <li>
              <label>Profile:</label>
              <span>
                <router-link :to="{ name: 'CustomerView', params: { id: post.user.id } }">
                  {{ post.user.uuid }} - {{ post.user.firstName }} {{ post.user.lastName }}
                </router-link>
              </span>
            </li>
            <li>
              <label>Auto-Published:</label>
              <span>{{ post.autoPublished ? 'Yes' : 'No' }}</span>
            </li>
            <li>
              <label>Post Classification:</label>
              <span>{{ post.classification | classification }}</span>
            </li>
            <li>
              <label>Reviewed At:</label>
              <span>
                <template v-if="post.reviewedAt">
                  {{ post.reviewedAt | date }}
                </template>
                <template v-else>
                  N/A
                </template>
              </span>
            </li>
            <li>
              <label>Reviewed By:</label>
              <span>
                <template v-if="post.reviewedBy">
                  {{ post.reviewedBy.firstName }} {{ post.reviewedBy.lastName }}
                </template>
                <template v-else>
                  N/A
                </template>
              </span>
            </li>
          </ul>
        </panel>

        <panel v-if="!post.reviewedAt && post.status !== 'EXPIRED'">
          <div class="items-center">
            <el-button
              type="success"
              size="small"
              icon="el-icon-check"
              @click="updateStatus('ACTIVE')">
              Approve
            </el-button>
            <el-button
              type="danger"
              size="small"
              icon="el-icon-close"
              @click="rejectDrawer = true">
              Reject
            </el-button>
          </div>
        </panel>
      </el-col>
    </el-row>

    <manage-offer
      v-if="manageOffer"
      :post-id="postId"
      @close="manageOffer = false" />

    <rejecting-reason
      v-if="rejectDrawer"
      @close="rejectDrawer = false"
      @reject="updateStatus('REJECTED', $event)" />

    <vue-gallery
      :images="images"
      :index="previewImage"
      @close="previewImage = null" />
  </div>
</template>

<script>
import store from '../../../store'

import VueGallery from 'vue-gallery'
import ManageOffer from '../offers/ManageOffer'
import RejectingReason from './RejectingReason'
import CustomOffersTable from '../offers/CustomOffersTable'

export default {
  async beforeRouteEnter (to, from, next) {
    const promises = [
      store.dispatch('posts/get', to.params.id),
      store.dispatch('offers/load'),
      store.dispatch('users/load')
    ]
    await Promise.all(promises)
    next()
  },

  components: {
    VueGallery,
    ManageOffer,
    RejectingReason,
    CustomOffersTable
  },

  data () {
    return {
      previewImage: null,
      rejectDrawer: false,
      manageOffer: false,
      loading: false
    }
  },

  computed: {
    postOffers () {
      let offers = store.getters['offers/list'] || []
      offers = offers.filter(offer => offer.status !== 'REJECTED' && offer.postOffers.some(postOffer => parseInt(postOffer.postId) === this.postId && ['ACCEPTED', 'PENDING', 'ACTIVE'].includes(postOffer.status)))

      offers = offers.filter(offer =>
        (parseInt(this.post.userId) === parseInt(this.user.id) ||
        parseInt(this.post.createdBy.id) === parseInt(this.user.id) ||
        parseInt(offer.createdBy.id) === parseInt(this.user.id) ||
        parseInt(offer.user.id) === parseInt(this.user.id)) ? true : offer.status === 'ACTIVE')

      return offers
    },

    image () {
      return this.post.image ? `/files/posts/${this.post.image}` : null
    },

    extraImages () {
      const imageKeys = ['image2', 'image3', 'image4', 'image5']
      return imageKeys.map(key => this.post[key]).filter(image => image).map(image => `/files/posts/${image}`)
    },

    images () {
      return this.image ? [this.image, ...this.extraImages] : this.extraImages
    },

    statusColors () {
      return window.postStatusColors
    },

    postId () {
      return parseInt(this.$route.params.id)
    },

    post () {
      return this.$store.getters['posts/get'](this.postId)
    },

    user () {
      return this.$store.state.user.user
    }
  },
  
  created () {
    const title = this.post.headline
    this.$store.commit('page/setPageInfo', {
      title,
      breadcrumbs: [
        {
          text: 'Listings',
          to: { name: 'Posts' }
        },
        {
          text: 'Posts',
          to: { name: 'Posts' }
        },
        {
          text: `Post - ${this.postId}`
        }
      ]
    })
  },

  methods: {
    async updateStatus (status, rejectingReason = null) {
      this.loading = true
      try {
        this.rejectDrawer = false
        await this.$store.dispatch('posts/update', {
          id: this.post.id,
          payload: {
            status,
            rejectingReason,
            reviewedAt: 'CURRENT_TIMESTAMP',
            reviewedBy: this.user.id
          }
        })
      } catch {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
